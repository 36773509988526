import React from 'react'
import Status from './Status'
import moment from 'moment'

const BlogStat = ({
  item: {
    title,
    subtitle,
    createdAt,
    happenedOn,
    fullName,
    approved,
    location,
    remark,
  },
}) => {
  return (
    <>
      <h2 className='card-title'>{title}</h2>
      <small className='card-subtitle'>{subtitle} </small>
      <small className='card-date'>
        Posted on <i className='far fa-clock'></i>{' '}
        {moment(createdAt).format('ll')}{' '}
      </small>
      {happenedOn && (
        <small className='card-date'>
          Happened on{' '}
          <strong>{moment(happenedOn).format('ll')}
           {
          remark && `(${remark})`
        }
          </strong>
        </small>
      )}
      
           

      <div className='profile-category'>
        <h4>{fullName}</h4>
        <small>{location} </small>
      </div>
      <Status approved={approved} />
    </>
  )
}
export default BlogStat
