import React ,{useState, useEffect, Fragment} from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import ApproveButton from '../shared/ApproveButton'
import SocialShareButton from '../shared/SocialShareButton'
import eventImage from '../../assets/images/event.jpg'
import moment from 'moment'
import { connect } from 'react-redux'

const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({ event, auth, approveEvent, deleteEvent, deleteEventImage, changeEventAccess, id }) =>{
    const [imageIndex , setImageIndex] = useState(0)
    const images = event.images
    let lock = event.public  ? "fas fa-lock-open": "fas fa-lock"
      const nextImage = () => {
      if(imageIndex < images.length){
        setImageIndex(imageIndex + 1)
      } else if (imageIndex == images.length && imageIndex > 0) {
 setImageIndex(imageIndex - 1)
      }
    }
     const previousImage = () => {
    if (imageIndex < images.length && imageIndex !== 0) {
      let previousIndex = imageIndex - 1
      setImageIndex(previousIndex)
    }
  }
  useEffect(() =>{
    if(!auth.isAuthenticated && !event.public){
     window.location = '/events'
    }
  })


  return  (
  <div className='single-student'>
    <div className='profile-header'>
      <div className='profile-category' style={{ textAlign: 'center' }}>
        <h4 className='card-title'>{event.title}</h4>
        <small className='card-subtitle'>{event.subtitle}</small>
        <br />
        <small className='card-date'>
          Published on <i className='far fa-clock'></i>{' '}
          {moment(event.createdAt).format('ll')}
        </small>
        <div>
        <h2>Event Time</h2>
        <small className='card-date'>
        {
          event.startAt && <>Starts:
          <strong>{moment(event.startAt).format('ll HH:mm')}</strong> <br /> </>
        }

        {
          event.endAt && <>Ends: <strong>{moment(event.endAt).format('ll HH:mm')}</strong></>
        }
          
      
        </small>
        
        </div>
        
          <ApproveButton
            auth={auth}
            item={event}
            onClick={approveEvent}
          />
      </div>
      {/* {event.imageUrl.length > 1 ? (
        <img
          src={event.imageUrl}
          style={imageStyles}
          className='blog-image'
          loading='lazy'
          alt={event.title}
        />
      ) : (
         <img
              src={eventImage}
              style={imageStyles}
              loading='lazy'
              className='card-image'
              alt={'Event card'}
            />
      )} */}

            <div className='gallery-image-full-size'>
      {
        images.length > 0  && images[imageIndex] ? 
        (
        <Fragment>
          {imageIndex > 0 && (
            <div className='gallery-arrow order-2'>
            <i
              className='fas fa-chevron-circle-left'
              onClick={previousImage}
            ></i>
             </div>
          )}
        <div className='single-img-full-support order-1'>
        <div  className ="image-count">
        {imageIndex + 1}/{images.length}
       </div>
             {auth.isAuthenticated && (
        <div className='icons'>
          <i 
          onClick = {() => deleteEventImage (images[imageIndex].imageId)}
            className={`btn-danger far fa-trash-alt`} style = {{color:'red', cursor:'pointer'}}
          
          ></i>
           <i 
          onClick = {() => changeEventAccess (event._id)}
            className={`btn-danger ${lock}`} style = {{color:'red', cursor:'pointer'}}
          ></i>
        </div>
      )}
         <img
          src={images[imageIndex].imageUrl}
          style={imageStyles}
          className='blog-image'
          loading='lazy'
          alt={event.title}/>
        </div>
        </Fragment>
        ) : (
        <div className='single-img-full order-1'>
            <img src={eventImage} className='' alt={event.title} />{' '}
          </div>
      )}
          {imageIndex + 1 !== images.length && (
            <div className='gallery-arrow'>
            <i className='fas fa-chevron-circle-right' onClick={nextImage}></i>
            </div>
          )}
    </div>
    </div>
    <div className='profile-body'>
      <div className='profile-category'>
        <p className='description'>{event.description}</p>
      </div>
    </div>
    <div className='profile-footer'>
      <div>
        <small className='card-date'>
          <i className='far fa-clock'></i> Published on{' '}
          {moment(event.createdAt).format('ll')}{' '}
        </small>
      </div>
      {auth.isAuthenticated && (
        <div className='icons'>
          <NavLink to={`/events/edit/${event._id}`} className='btn-primary'>
            {' '}
            <i className='far fa-edit'></i>
          </NavLink>

          <i
            className='btn-danger far fa-trash-alt'
            onClick={deleteEvent}
          ></i>
        </div>
      )}
      <div>
        <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton category={'events'} id={event._id} />
        </div>
      </div>
    </div>
  </div>
)
}

const EventDetail = (props) => {
  const { auth } = props
  const deleteEvent = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/events/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/events')
  }

    const approveEvent = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/events/approve/${id}`
    axios
      .put(url)
      .then((response) => {})
      .catch((err) => {})
  }
    const deleteEventImage = (imageId) => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/events/${id}/image/${imageId}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
  }
  const changeEventAccess = (id) => {
    const url = `/api/v1.0.0/events/${id}/access`
     axios.put(url).then((response) => {
      return
    })
    props.fetchData()
  }

  let renderContent

  if (props.events.length > 0) {
    const event = props.events.find((evt) => evt._id == props.match.params.id)
    renderContent = (
      <Details 
      event={event}
      auth={auth} 
      deleteEvent={deleteEvent} 
      approveEvent = {approveEvent}
      deleteEventImage = {deleteEventImage}
      changeEventAccess = {changeEventAccess}

      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout>
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

EventDetail.propTypes = {}
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(EventDetail)
