import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import ShowActions, { isAdmin } from '../shared/ShowActions'
import ReactMarkdown from 'react-markdown'
import massacreImage from '../../assets/images/massacre.jpg'
import renderHTML from 'react-render-html'
import Status from '../shared/Status'
import BlogStat from '../shared/BlogStat'

const MassacreRow = ({ massacre }) => {
  let latestPost =
    (new Date().getDate() - new Date(massacre.createdAt).getDate()) * -1
  if (Object.keys(massacre).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <>
        <tr className='table-row'>
         <td className="case-num">
              {massacre.caseNum && 

                 <NavLink to={`/massacres/${massacre._id}`}> {massacre.caseNum}</NavLink>
                
              }
            </td>
         <td>
              {massacre.shortDescription && massacre.shortDescription
              }
            </td>
        <td>{massacre.location && massacre.location}</td>
        <td>{massacre.happenedOn && moment(massacre.happenedOn).format('ll')}

        {
          massacre.remark && `(${massacre.remark})`
        }
        
        
        </td>
        <td>{
          massacre.evidence &&  massacre.evidence.split(', ').length > 1  ?  massacre.evidence.split(', ').map((link) => <> <a style = {{display:'block', margin:'8px 0'}}href = {link}>{link}</a> </> ) : <a href = {massacre.evidence}>{massacre.evidence}</a>
        }</td>
        <td>
        {
          massacre.quantity &&  massacre.quantity
        }
       
       
        </td>
          
        </tr>
    </>
  )
}

export default MassacreRow
