import React from 'react'
import { NavLink } from 'react-router-dom'
import Orphan from './Orphan'
import PropTypes from 'prop-types'
import Header from '../Header'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Orphans = (props) => {
  let renderContent

  if (props.data.length > 0) {
    renderContent = props.data.map((orphan, i) => (
      <div key={orphan._id} className='students'>
        <NavLink to={`/orphans/${orphan._id}`}>
          <Orphan orphan={orphan} />
        </NavLink>
      </div>
    ))
  } else if (props.data.length == 0) {
    renderContent = <p>No data has been found</p>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Orphans  ' + props.data.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}

export default Orphans
