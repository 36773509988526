import React from 'react'
import SocialMedias from './social_media/social-media'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const year = new Date().getFullYear()

const Footer = (props) => {
  return (
    <footer>
      <div className='footer-wrapper'>
        <div>
          <h1>Contact</h1>
          <small>Email: genocide.amhara@gmail.com</small>
        </div>

        <div className='copyright'>
          <small>© {year} Amhara Genocide. All Rights Reserved.</small>
          <div className='arrow'>
            <a href='#root' rel='noopener noreferrer'>
              <i className='fas fa-arrow-alt-circle-up'></i>
            </a>
          </div>
        </div>
        <div>
          <a
            href='https://twitter.com/AmharaHRGW'
            rel='noopener noreferrer'
            target='_blank'
          >
            {' '}
            <i className='fab fa-twitter'></i>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
