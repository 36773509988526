import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import massacreImage from '../../assets/images/massacre.jpg'
import renderHTML from 'react-render-html'
import Status from '../shared/Status'
import BlogStat from '../shared/BlogStat'

const Massacre = ({ massacre }) => {
  const images = massacre.images
  const index = Math.floor(Math.random () * images.length)

  let latestPost =
    (new Date().getDate() - new Date(massacre.createdAt).getDate()) * -1
  if (Object.keys(massacre).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <>
      <div className='student'>
        <div className='card'>
          {images.length > 0 ? (
            <img
              src={images[0].imageUrl}
              style={imageStyles}
              className='blog-card-image'
              alt={massacre.title}
            />
          ) : (
            <img
              src={massacreImage}
              style={imageStyles}
              className='blog-card-image'
              alt={massacre.title}
            />
          )}
          <div>
            {/* <h2 className='card-title'>{renderHTML(massacre.title)}</h2>
            <small className='card-subtitle'>{massacre.subtitle} </small>
            <small className='card-date'>
              Published on <i className='far fa-clock'></i>{' '}
              {moment(massacre.createdAt).format('ll')}{' '}
            </small>
            <Status approved={massacre.approved} /> */}
            <BlogStat item={massacre} />
       
            <div>
              {massacre.description && (
                <ReactMarkdown source={massacre.description.slice(0, 250)} />
              )}
              <br />
              <small className='read-more'>Read More</small>
            </div>
          </div>
          {/* {latestPost < 3 && (
            <div>
              <div className='post-badge'>NEW</div>
            </div>
          )} */}
        </div>
      </div>
    </>
  )
}

export default Massacre
