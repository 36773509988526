import React, { useState, useRef } from 'react'
import axios from 'axios'
import TextInputField from './shared/TextInputField'
import TextAreaField from './shared/TextAreaField'
import SelectListGroup from './shared/SelectListGroup'
import {addresses} from '../utils/address_list'

const textMessage = `As a constituent, I request that the Member raise awareness about the continued massacres and displacement of ethnic Amhara civilians in various parts of Ethiopia. 

Over the weekend of March 31, more than 180 ethnic Amharas were massacred by Oromo Liberation Army militants in  Wollega, Oromia region.

In February, the ruling party in the country's Benishangul-Gumuz Region admitted to their complicity in a series of massacres that have killed nearly 400 Amharas and displaced nearly 200,000 from the area since September: https://addisstandard.com/news-benishangul-gumuz-region-ruling-party-admits-ethnic-targeted-killings-in-metekel-issues-an-apology-sacks-senior-members/

According to preliminary investigations by the human rights community, on November 9, at least several hundred Amhara civilians were killed by forces loyal to the Tigray People's Liberation Front in the town of Mai-Kadra. On November 1, Amnesty International reported at least 54 Amharas were killed by the Oromo Liberation Army in West Wolega, Oromia Region. And from October 18-21, unknown militants killed at least 31 Amharas in Guraferda, Southern Nations, Nationalities, and Peoples Region. 

I believe that the roots of this violence lie in Ethiopia's ethnic federalist Constitution. This forces millions of ethnic Amharas outside of the "Amhara Region" to have second class political, civil, and economic rights, and makes subject to persecution by local governments and militants. Unfortunately, state security forces have been either unwilling or unable to protect civilian Amharas during all of these attacks. 

As such, I ask that the Member strongly condemn these massacres, call on the Ethiopian Government to protect civilians from future attacks, and for Ethiopians to come together and pursue genuine long-term political reform to stabilize the country. 

Additional Sources:

https://www.nytimes.com/2021/01/13/world/africa/ethiopia-ethnic-killings.html

https://reliefweb.int/report/ethiopia/ethiopia-benishangul-gumuz-region-flash-update-6-january-2021

https://www.reuters.com/article/us-ethiopia-violence/death-toll-from-attack-in-western-ethiopia-reaches-222-red-cross-says-idUSKBN28Z0U0

https://www.amnesty.org/en/latest/news/2020/12/ethiopia-at-least-100-dead-surge-of-violence-against-ethnic-minorities/

https://www.amnesty.org/en/latest/news/2020/11/ethiopia-investigation-reveals-evidence-that-scores-of-civilians-were-killed-in-massacre-in-tigray-state/

https://www.amnesty.org/en/latest/news/2020/11/ethiopia-over-50-ethnic-amhara-killed-in-attack-on-village-by-armed-group/

https://www.amnesty.org/en/latest/news/2020/10/ethiopia-authorities-ban-protests-as-illegal-and-unnecessary/`

const AddReport = (props) => {
  const initialState = {
		email: '',
		firstName: '',
		lastName: '',
		country: '',
		province: '',
		district: '',
		city: '',
		streetName: '',
		zipcode: '',
		authorityEmail: '',
		message: textMessage,
	}
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(initialState)

  // copying

  const mailToFun = () => {
	  window.location = `mailto:${formData.authorityEmail}`
  }
  
   const [copySuccess, setCopySuccess] = useState('');
		const [copied, setCopied] = useState(false);
		const [textareaColor, setTextareaColor] = useState(false);
		const [feedback, setFeedback] = useState('');
		const textAreaRef = useRef(null);
      const copyResult = () => {
				navigator.clipboard.writeText(formData.message);
				setCopied(true);
				setTextareaColor(true);
				setTimeout(() => {
					setCopied(false);
				}, 3000);
				mailToFun()
			};
  const onChange = (e) => {
    const { name, value } = e.target
    console.log(value)
    console.log(name)
    setFormData({ ...formData, [name]: value })
  }


  const onSubmit = (e) => {
    e.preventDefault()
    console.log(formData)
    const url = '/api/v1.0.0/reports'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    axios({
      method: 'post',
      url: url,
      data: formData,
    })
      .then((response) => {
        props.fetchData()
        // props.history.push('/blogs')
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }

  const { firstName, lastName, authorityEmail, email, country, city, streetName, zipcode, message } = errors

  let countries = addresses.map((address) => {
    return {
      label: address.country,
      value: address.country 
    }
  }
    )

  let provinces = [{label:'--Select Province --', value:''}]
  let districts = [{label:'--Select District --', value:''}]

  for (const country of addresses){
    if(country.country == formData.country) {
      formData.message = country.message
      provinces =  [{label:'--Select Province --', value:''}, ... Object.keys(country.provinces).map((prov) => {
        return {
          label:prov,
     value:prov
        }
      })]
      if (formData.province  && formData.country && formData.district){
             provinces =  [{label:'--Select Country --', value:''}, ... Object.keys(country.provinces).map((prov) => {
        return {
          label:prov,
     value:prov
        }
      })]

    }


  if(formData.province && country.provinces[formData.province] !=undefined){
          districts =  [{label:'--Select District --', value:''}, ... country.provinces[formData.province].map((prov) => {
        return {label:prov.district,value:prov.district, email:prov.email}
      })]
      } else {

      }
    } 
  }

    let selectedDistrict = districts.find((d) => formData.district == d['label'])
if(selectedDistrict!=null || selectedDistrict != undefined){
  formData.authorityEmail = selectedDistrict.email
}

 const status = copied ? 'copied' : 'copy';
 const textAreaBg =
		textareaColor && formData.message.length > 0 ? 'text-area-bg' : '';


  return (
		<section className='newsletter-section'>
			<h1 className='newsletter-title'>
				Inform Authorities About Ongoing Amhara Genocide
			</h1>
			<p>
				Tell Congress About the Massacres of Amharas in Metekel, Welega, and Mai
				Kadra!
			</p>
			<small>
				Send the message in the text box by clicking the send button to create
				awareness about the ongoing Amhara Genocide.
			</small>
			{feedback && (
				<div>
					<i class='fas fa-check'></i> <br />
					<small style={{ color: 'green' }}>{feedback}</small>
				</div>
			)}
			<form onSubmit={onSubmit} noValidate className='subscribe-form'>
				<div className='row-subscribe'>
					<div className='row'>
						<TextInputField
							id='firstName'
							type='text'
							name='firstName'
							value={formData.firstName}
							onChange={onChange}
							placeholder='Your first name'
							error={firstName}
						/>
						<TextInputField
							id='lastName'
							type='text'
							name='lastName'
							value={formData.lastName}
							onChange={onChange}
							placeholder='Your last name'
							error={lastName}
						/>
						<TextInputField
							id='email'
							type='email'
							name='email'
							value={formData.email}
							onChange={onChange}
							placeholder='Your email'
							error={email}
						/>
					</div>
					{/* <div className='row'>
            <TextInputField
              id='country'
              type='country'
              name='country'
              value={formData.country}
              onChange={onChange}
              placeholder='Country'
              error={country}
            />
        
          </div> */}
					<div className='row'>
						<TextInputField
							id='City'
							type='city'
							name='city'
							value={formData.city}
							onChange={onChange}
							placeholder='City'
							error={city}
						/>
						<TextInputField
							id='zipcode'
							type='zipcode'
							name='zipcode'
							value={formData.zipcode}
							onChange={onChange}
							placeholder='Zip Code'
							error={zipcode}
						/>
						<TextInputField
							id='streetName'
							type='streetName'
							name='streetName'
							value={formData.streetName}
							onChange={onChange}
							placeholder='Street Name'
							error={streetName}
						/>
					</div>
					<hr />
					{/* <div className='row'>
						<TextInputField
							id='authorityEmail'
							type='email'
							name='authorityEmail'
							value={formData.authorityEmail}
							onChange={onChange}
							placeholder='Authority Email'
							error={authorityEmail}
						/>
					</div> */}

					<div className='row'>
						<SelectListGroup
							id='country'
							type='country'
							name='country'
							value={formData.country}
							onChange={onChange}
							placeholder='country'
							error={'country'}
							options={[
								{ label: '--select country--', value: '' },
								...countries,
							]}
						/>
						<SelectListGroup
							id='province'
							type='province'
							name='province'
							value={formData.province}
							onChange={onChange}
							placeholder='province'
							error={'province'}
							options={provinces}
						/>
						<SelectListGroup
							id='district'
							type='district'
							name='district'
							value={formData.district}
							onChange={onChange}
							placeholder='district'
							error={'district'}
							options={districts}
						/>
					</div>

					<TextAreaField
						label='Subject: Condemn Ethnic Cleansing of Amhara Civilians in Ethiopia
Message:'
						id='message'
						name='message'
						value={formData.message}
						onChange={onChange}
						placeholder='Write your message here'
						error={message}
						rows='30'
					/>
				</div>
				<div className={`wrapper__copy ${status}`}>
					<i className='fas fa-copy' onClick={copyResult}></i>
				</div>
				{/* <div className='row-subscribe-btn'>
					<button className='btn subscribe-btn'>Send</button>
				</div> */}
			</form>
		</section>
	);
}

export default AddReport
