import React from 'react'

const Status = (props) => {
  return props.approved ? (
    <div className="status">
      <small>
        Confirmed <i className='fas fa-check-circle'></i>
      </small>
    </div>
  ) : (
    <div>
      <small style={{ color: 'red' }}>Not Confirmed</small> <br />
    </div>
  )
}

export default Status
