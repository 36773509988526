import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown/with-html'
import economyImage from '../../assets/images/history_1.jpg'
import Status from '../shared/Status'
import ShowActions, { isAdmin, onlyAdmin } from '../shared/ShowActions'
import ApproveButton from '../shared/ApproveButton'
import BlogStat from '../shared/BlogStat'
import SocialShareButton from '../shared/SocialShareButton'
const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({ victim, auth, approveVictim, deleteVictim, id }) => {
  const { isAuthenticated, user } = auth
  const userId = user.id
  return (
    <div className='single-student'>
      <div className='profile-header'>
        <div className='profile-category' style={{ textAlign: 'center' }}>
          {/* <h4 className='card-title'>{victim.title}</h4>
          <small className='card-subtitle'>{victim.subtitle}</small>
          <br />
          <small className='card-date'>
            Published on <i className='far fa-clock'></i>{' '}
            {moment(victim.createdAt).format('ll')}
          </small>
          <Status approved={victim.approved} /> */}
          <BlogStat item={victim} />
          <ApproveButton auth={auth} item={victim} onClick={approveVictim} />

          {/* <div className='profile-category'>
            <h4>{victim.fullName}</h4>
            <small>{victim.location} </small>
          </div> */}

          {/* {victim.imageUrl.length > 1 ? (
          <img
            src={victim.imageUrl}
            style={imageStyles}
            className='blog-image'
            alt={victim.title}
          />
        ) : (
          <img
            src={economyImage}
            style={imageStyles}
            className='blog-image'
            alt={victim.title}
          />
        )} */}
        </div>
      </div>
      <div className='profile-body'>
        <div className='profile-category'>
          <div className='description'>
            {<ReactMarkdown source={victim.description} />}
          </div>
          <div className='description-image'>
            {victim.imageUrl.length > 1 && (
              <img
                src={victim.imageUrl}
                style={imageStyles}
                className='blog-image'
                alt={victim.title}
              />
            )}
          </div>
        </div>
      </div>
      <div className='profile-footer'>
        <div>
          <small className='card-date'>
            <i className='far fa-clock'></i> Published on{' '}
            {moment(victim.createdAt).format('ll')}{' '}
          </small>
        </div>
        <div>
          {/* <SocialMediaShareButton
            Component={FacebookShareButton}
            url={`https://amharagenocide.net/victims/${victim._id}`}
            quote={'Ongoing genocide on Amhara people'}
            hashtag='#StopAmharaGenocide'
            Icon={FacebookIcon}
          />{' '}
          <SocialMediaShareButton
            Component={FacebookShareButton}
            url={`https://amharagenocide.net/victims/${victim._id}`}
            quote={'Ongoing genocide on Amhara people'}
            hashtag='#StopAmharaGenocide'
            Icon={TwitterIcon}
          />{' '}
          <SocialMediaShareButton
            Component={TelegramShareButton}
            url={`https://amharagenocide.net/victims/${victim._id}`}
            quote={'Ongoing genocide on Amhara people'}
            hashtag='#StopAmharaGenocide'
            Icon={TelegramIcon}
          /> */}
          <div>
            <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
            <SocialShareButton category={'victims'} id={victim._id} />
          </div>
        </div>
        <ShowActions
          isAuthenticated={isAuthenticated}
          auth={auth}
          item={victim}
          deleteItem={deleteVictim}
          name='victims'
        />
      </div>
    </div>
  )
}

const VictimDetail = (props) => {
  const { auth } = props
  const deleteVictim = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/victims/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/victims')
  }
  const approveVictim = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/victims/approve/${id}`
    axios
      .put(url)
      .then((response) => {})
      .catch((err) => {})
  }

  let renderContent
  let item

  if (props.data.length > 0) {
    const victim = props.data.find((st) => st._id == props.match.params.id)
    item = victim
    renderContent = (
      <Details
        victim={victim}
        auth={auth}
        approveVictim={approveVictim}
        deleteVictim={deleteVictim}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout
      title={item && item.title}
      imageUrl={item && item.imageUrl}
      category='victims'
      id={item && item._id}
    >
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(VictimDetail)
