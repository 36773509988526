import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Header from '../Header'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import SelectListGroup from '../shared/SelectListGroup'
import { countries } from '../../utils/countries'
import { showDateTime } from '../../utils/show_day_time'
import moment from 'moment'
import styles from '../shared/input-file.module.scss'

const EditMissing = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    quantity: '',
    location: '',
    happenedOn: '',
    description: '',
    createdAt: '',
    image: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const url = `/api/v1.0.0/missings`
    axios
      .get(url)
      .then((response) => {
        const id = props.match.params.id
        const event = response.data.find((st) => st._id == id)

        const {
          title,
          subtitle,
          quantity,
          location,
          victimizer,
          description,
          happenedOn,
          createdAt,
        } = event

        const data = {
          title,
          subtitle,
          quantity,
          location,
          victimizer,
          description,
          happenedOn,
          createdAt,
        }
        setFormData(data)
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/missings/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const imageData = new FormData()
    for (const key in formData) {
      imageData.append(key, formData[key])
    }

    axios({
      method: 'put',
      url: url,
      data: imageData,
    }).then((response) => {})
    props.fetchData()
    props.history.push(`/missings/${id}`)
  }

  const createdAtFormatted = moment(formData.createdAt).format(
    'YYYY-MM-DDTHH:mm:ss'
  )
  const happenedOnFormatted = moment(formData.happenedOn).format(
    'YYYY-MM-DDTHH:mm:ss'
  )

  const {
    title,
    subtitle,
    quantity,
    location,
    victimizer,
    description,
    happenedOn,
    createdAt,
    image,
  } = errors

  return (
    <Layout title='Edit'>
      <form onSubmit={onSubmit} noValidate>
        <div className='row'>
          <TextInputField
            label='Title'
            id='title'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />
          <TextInputField
            label='Subtitle'
            id='subtitle'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='Subtitle'
            error={subtitle}
          />
          <TextInputField
            label='Location'
            id='location'
            name='location'
            value={formData.location}
            onChange={onChange}
            placeholder='Region, zone, woreda, kebele'
            error={location}
          />
        </div>

        <div className='row'>
          <TextInputField
            label='Number of people displaced'
            id='quantity'
            name='quantity'
            value={formData.quantity}
            onChange={onChange}
            placeholder='የተፈናቀለ ሰው ባዛት'
            error={quantity}
          />
          <TextInputField
            label='Victimizer'
            id='victimizer'
            name='victimizer'
            value={formData.victimizer}
            onChange={onChange}
            placeholder='ገዳይ/ገዳዮች'
            error={victimizer}
          />
          <TextInputField
            label='Diplacement  Date and time'
            type='datetime-local'
            id='happenedOn'
            name='happenedOn'
            value={formData.happenedOn}
            onChange={onChange}
            error={happenedOn}
          />
        </div>
        <div className='row'>
          <TextInputField
            label='Published on'
            type='datetime-local'
            id='createdAt'
            name='createdAt'
            value={formData.createdAt}
            onChange={onChange}
            error={createdAt}
          />
        </div>

        <TextAreaField
          label='Description'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Your post  goes here ...'
          error={description}
        />

        <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
            className={styles.inputfile}
          />
          <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload Image
          </label>
        </div>

        <button className='btn' type='submit' id='submit-button'>
          Update
        </button>
      </form>
    </Layout>
  )
}

export default EditMissing
