export const ADD_PRESENTATION = "ADD_PRESENTATION";
export const EDIT_PRESENTATION = "EDIT_PRESENTATION";
export const DELETE_PRESENTATION = "DELTE_PRESENTATION";
export const LOADING_PRESENTATION = "LOADING_PRESENTATION";


export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_PRESENTATION = "GET_PRESENTATION";
export const GET_PRESENTATIONS = "GET_PRESENTATIONS";
