import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import { connect } from 'react-redux'
import Status from '../shared/Status'
import ShowActions, { isAdmin } from '../shared/ShowActions'
import ApproveButton from '../shared/ApproveButton'
import BlogStat from '../shared/BlogStat'
import SocialShareButton from '../shared/SocialShareButton'

const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({ video, auth, deleteStudent, approveVideo, id }) => {
  const { isAuthenticated, user } = auth
  const userId = user.id

  return (
    <div className='single-student'>
      <div className='profile-header'>
        <div className='profile-category' style={{ textAlign: 'center' }}>
          {/* <h4 className='card-title'>{video.title}</h4>
          <small className='card-subtitle'>{video.subtitle}</small>
          <br />
          <small className='card-date'>
            Published on <i className='far fa-clock'></i>{' '}
            {moment(video.createdAt).format('ll')}
          </small>
          <Status approved={video.approved} approve={approveVideo} /> */}
          <BlogStat item={video} />
          <ApproveButton auth={auth} item={video} onClick={approveVideo} />
        </div>
        {video.videoUrl.length > 1 ? (
          <video
            src={video.videoUrl}
            style={imageStyles}
            className='blog-image'
            alt={video.title}
            controls
          />
        ) : (
          <i className='fas fa-user'></i>
        )}
      </div>
      <div className='profile-body'>
        <div className='profile-category'>
          <p className='description'>{video.description}</p>
        </div>
      </div>
      <div className='profile-footer'>
        <div>
          <small className='card-date'>
            <i className='far fa-clock'></i> Published on{' '}
            {moment(video.createdAt).format('ll')}{' '}
          </small>
        </div>

        <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton category={'videos'} id={video._id} />
        </div>

        <ShowActions
          isAuthenticated={isAuthenticated}
          auth={auth}
          item={video}
          deleteItem={deleteStudent}
          name='videos'
        />

        {/* {((isAuthenticated && userId == video.userId) ||
          user.role == 'admin' ||
          user.role == 'super') && (
          <div className='icons'>
            <NavLink to={`/videos/edit/${video._id}`} className='btn-primary'>
              {' '}
              <i className='far fa-edit'></i>
            </NavLink>

            <i
              className='btn-danger far fa-trash-alt'
              onClick={deleteStudent}
            ></i>
          </div>
        )} */}
      </div>
    </div>
  )
}
const VideoDetail = (props) => {
  const { auth } = props
  const deleteStudent = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/videos/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    window.location.href = '/videos'
  }
  const approveVideo = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/videos/approve/${id}`
    axios
      .put(url)
      .then((response) => {})
      .catch((err) => {})
  }

  let renderContent
  let item

  if (props.videos.length > 0) {
    const video = props.videos.find((st) => st._id == props.match.params.id)
    item = video
    renderContent = (
      <Details
        video={video}
        auth={auth}
        deleteStudent={deleteStudent}
        approveVideo={approveVideo}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout
      title={item && item.title}
      imageUrl={item && item.imageUrl}
      category='videos'
      id={item && item._id}
    >
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

VideoDetail.propTypes = {}
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(VideoDetail)
