import React from 'react'
import { NavLink } from 'react-router-dom'

export const onlyAdmin = (auth) => {
  const { isAuthenticated, user } = auth
  let actions =
    (isAuthenticated && user.role == 'admin') || user.role == 'super'
  return actions
}

export const isAdminOrUser = (auth, item) => {
  const { isAuthenticated, user } = auth
  const userId = user.id
  let actions
  if (isAuthenticated) {
    actions =
      userId == item.userId || user.role == 'admin' || user.role == 'super'
  }
  return actions
}

const ShowActions = ({ auth, item, deleteItem, name }) => {
  let result = isAdminOrUser(auth, item)

  return result ? (
    <div className='icons'>
      <NavLink to={`/${name}/edit/${item._id}`} className='btn-primary'>
        {' '}
        <i className='far fa-edit'></i>
      </NavLink>
      <i className='btn-danger far fa-trash-alt' onClick={deleteItem}></i>
    </div>
  ) : (
    ''
  )
}
export default ShowActions
