import React from 'react'
import BlogStat from '../shared/BlogStat'
import PropTypes from 'prop-types'
import moment from 'moment'
import eventImage from '../../assets/images/event.jpg'

const Event = (props) => {
  const images = props.event.images
  if (Object.keys(props.event).length < 1) {
    return <h1>Loading...</h1>
  }

  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  let createdAtFormatted = moment(props.event.createdAt).format('ll HH:mm')
  let startAtFormatted = moment(props.event.startAt).format('ll HH:mm')
  let endAtFormatted = moment(props.event.endAt).format('ll HH:mm')

  return (
    <>
      <div className='student'>
        <div className='card'>
            {images.length > 0 ? (
            <img
              src={images[0].imageUrl}
              style={imageStyles}
              loading='lazy'
              className='blog-card-image'
              alt={props.event.title}
            />
          ) : (
            <img
              src={eventImage}
              style={imageStyles}
              className='blog-card-image'
              loading='lazy'
              alt={props.event.title}
            />
          )}
          <div>

            <BlogStat item={props.event} />
            <div>
            
        <h2 style = {{fontWeight:'900'}}>Event Time</h2>
            {
              startAtFormatted && <small  className='card-date'>Starts: {startAtFormatted}</small>
             
            }
            {
               endAtFormatted && <small  className='card-date'>Ends: {endAtFormatted}</small>
            }
            
            </div>
            <div>
            <address>
            <p>{props.event.country}, {props.event.city}, {props.event.streetName}</p>

            
            </address>
            </div>
            <div>
              <p>
                {props.event.description &&
                  props.event.description.slice(0, 150)}{' '}
                ...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Event.propTypes = {}

export default Event
