import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import ogImage from '../assets/images/amhara-genocide-og-cover.png'

const SEO = (props) => {
  let title =
    props.title && props.title.length > 0
      ? `Amhara Genocide: ${props.title}`
      : 'Amhara Genocide'
  let imageUrl =
    props.imageUrl && props.imageUrl.length > 0 ? props.imageUrl : ogImage

  let url
  if (props.id) {
    url = `https://amharagenocide.net/${props.category}/${props.id}`
  } else if (props.category) {
    url = 'https://amharagenocide.net'
  }

  return (
    <Helmet>
      <meta charset='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta
        name='description'
        content='Ongoing Amhara genocide 1991 - 2021, Amhara genocide, Amhara Massacre'
      />
      <title>{title}</title>
      <meta
        property='og:title'
        content='Amhara Genocide, on going Amhara Genocide in Oromia, Benshangul-Gumez, Maikadra'
      />
      <meta property='og:type' content='website' />

      <meta property='og:image' content={imageUrl} />
      <meta property='og:description' content='Amhara Genocide' />
      <meta property='og:url' content='https://amharagenocide.net' />

      <meta name='twitter:title' content='Amhara Genocide ' />
      <meta name='twitter:description' content='Amhara Genocide' />
      <meta name='twitter:image' content={imageUrl} />
      <meta name='twitter:card' content='summary_large_image' />
    </Helmet>
  )
}

SEO.propTypes = {}

export default SEO
