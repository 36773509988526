import React from 'react'
import axios from 'axios'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import Home from './components/Home'
import About from './components/About'
import Events from './components/event/Events'
import EventDetail from './components/event/EventDetail'
import EditEvent from './components/event/EditEvent'
import Orphans from './components/orphan/Orphans'
import OrphanDetail from './components/orphan/OrphanDetail'
import EditOrphan from './components/orphan/EditOrphan'

import Missings from './components/missing/Missings'
import MissingDetail from './components/missing/MissingDetail'
import EditMissing from './components/missing/EditMissing'

import LinkURLs from './components/link-url/LinkURLs'
import LinkURLDetail from './components/link-url/LinkURLDetail'
import EditLinkURL from './components/link-url/EditLinkURL'

import Victims from './components/victim/Victims'
import VictimDetail from './components/victim/VictimDetail'
import EditVictim from './components/victim/EditVictim'
import Admin from './components/admin/Admin'
import ImageDetail from './components/gallery/ImageDetail'
import Images from './components/gallery/Images'
import EditImage from './components/gallery/EditImage'

import PrivateRoute from './components/shared/PrivateRoute'
import store from './store/store'
import Massacres from './components/massacre/Massacres'
import MassacreDetail from './components/massacre/MassacreDetail'
import EditMassacre from './components/massacre/EditMassacre'

import DisplacementDetail from './components/displacement/DisplacementDetail'
import EditDisplacement from './components/displacement/EditDisplacement'
import Displacements from './components/displacement/Displacements'
import Videos from './components/video/Videos'
import VideoDetail from './components/video/VideoDetail'
import EditVideo from './components/video/EditVideo'

import ActivationEmailMessage from './components/auth/ActivationEmailMessage'
import SignUp from './components/auth/SignUp'
import SignIn from './components/auth/SignIn'

import UserActivation from './components/auth/UserActivation'
import ResetPassword from './components/auth/ResetPassword'
import ForgotPassword from './components/auth/ForgotPassword'
import CheckYourEmail from './components/auth/CheckYourEmail'

import setAuthToken from './utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import { signOut, setCurrentUser } from './store/actions/authActions'

// import ScrollToTop from 'react-router-scroll-top'
import ScrollToTop from './ScrollToTop'
import Reports from './components/Reports'

// AWS, Google cloud, Azure, Heroku, Digital Ocean,

//check for token
if (localStorage.jwtToken) {
  //set auth token header auth
  setAuthToken(localStorage.jwtToken)
  //Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken)
  //set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))
  //check for expired token
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    //logout user
    axios
      .get('/api/users/signout')
      .then((res) => {
       
      })
      .catch((err) => console.log(err))
    store.dispatch(signOut())
    //Redirect to login;
    window.location.href = '/signin'
  }
}

class App extends React.Component {
  state = {
    victims: [],
    errors: {},
    user: {},
    users: [],
    massacres: [],
    videos: [],
    displacements: [],
    orphans: [],
    missings: [],
    linkURLs: [],
    events:[]
  }
  constructor(props) {
    super(props)
    const url = window.location.origin
    if (!url.includes('localhost') && !url.includes('https')) {
      window.location = `https:${url.split(':')[1]}`
    }
  }
  componentDidMount() {
    this.fetchMassacres()
    this.fetchDisplacements()
    this.fetchVideos()
    this.fetchVictims()
    this.fetchOrphans()
    this.fetchMissings()
    this.fetchLinkURLs()
    this.fetchEvents()
  }
    fetchEvents = () => {
    const url = '/api/v1.0.0/events'
    axios
      .get(url)
      .then((response) => {
        this.setState({ events: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchLinkURLs = () => {
    const url = '/api/v1.0.0/links'
    axios
      .get(url)
      .then((response) => {
        this.setState({ linkURLs: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchMissings = () => {
    const url = '/api/v1.0.0/missings'
    axios
      .get(url)
      .then((response) => {
        this.setState({ missings: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchVictims = () => {
    const url = '/api/v1.0.0/victims'
    axios
      .get(url)
      .then((response) => {
        this.setState({ victims: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchNews = () => {
    const url = '/api/v1.0.0/news'
    axios
      .get(url)
      .then((response) => {
        this.setState({ newsList: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchMassacres = () => {
    const url = '/api/v1.0.0/massacres'
    axios
      .get(url)
      .then((response) => {
        this.setState({ massacres: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchVideos = () => {
    const url = '/api/v1.0.0/videos'
    axios
      .get(url)
      .then((response) => {
        this.setState({ videos: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchOrphans = () => {
    const url = '/api/v1.0.0/orphans'
    axios
      .get(url)
      .then((response) => {
        this.setState({ orphans: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }

  fetchDisplacements = () => {
    const url = '/api/v1.0.0/displacements'
    axios
      .get(url)
      .then((response) => {
     
        if (response != undefined) {
          this.setState({ displacements: response.data })
        }
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }

  render() {
    const { displacements, orphans, missings, massacres, victims } = this.state

    const allData = [
      ...displacements,
      ...orphans,
      ...missings,
      ...massacres,
      ...victims,
    ].filter((item) => item.imageUrl != undefined)
      .map(
        ({
          _id,
          title,
          subtitle,
          happenedOn,
          createdAt,
          approved,
          imageUrl,
          location,
          description,
        }) => {
          return {
            _id,
            title,
            subtitle,
            happenedOn,
            imageUrl,
            location,
            createdAt,
            approved,
            description,
          }
        }
      )

    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Route path='/about' component={About} />
            <PrivateRoute
              exact
              path='/images/edit/:id'
              component={(props) => (
                <EditImage
                  {...props}
                  events={this.state.images}
                  fetchData={this.fetchImages}
                />
              )}
            />

            <Route
              exact
              path='/images'
              component={(props) => (
                <Images
                  {...props}
                  images={allData}
                  fetchData={this.fetchImages}
                />
              )}
            />
            <Route
              exact
              path='/images/:id'
              component={(props) => (
                <ImageDetail
                  {...props}
                  images={allData}
                  // fetchData={this.fetchImages}
                />
              )}
            />
            <Route
              exact
              path='/victims'
              component={(props) => (
                <Victims
                  {...props}
                  data={this.state.victims}
                  fetchData={this.fetchVictims}
                />
              )}
            />
            <Route
              exact
              path='/victims/:id'
              component={(props) => (
                <VictimDetail
                  {...props}
                  data={this.state.victims}
                  fetchData={this.fetchVictims}
                />
              )}
            />
            <PrivateRoute
              exact
              path='/victims/edit/:id'
              component={(props) => (
                <EditVictim
                  {...props}
                  data={this.state.victims}
                  fetchData={this.fetchVictims}
                />
              )}
            />
            <Route
              exact
              path='/massacres'
              component={(props) => (
                <Massacres
                  {...props}
                  data={this.state.massacres}
                  fetchData={this.fetchMassacres}
                />
              )}
            />
            <Route
              exact
              path='/massacres/:id'
              component={(props) => (
                <MassacreDetail
                  {...props}
                  data={this.state.massacres}
                  fetchData={this.fetchMassacres}
                />
              )}
            />
            <Route
              exact
              path='/massacres/edit/:id'
              component={(props) => (
                <EditMassacre
                  {...props}
                  data={this.state.massacres}
                  fetchData={this.fetchMassacres}
                />
              )}
            />
            {/*  */}
            <Route
              exact
              path='/videos'
              component={(props) => (
                <Videos
                  {...props}
                  videos={this.state.videos}
                  fetchData={this.fetchVideos}
                />
              )}
            />
            <Route
              exact
              path='/videos/:id'
              component={(props) => (
                <VideoDetail
                  {...props}
                  videos={this.state.videos}
                  fetchData={this.fetchVideos}
                />
              )}
            />
            <Route
              exact
              path='/videos/edit/:id'
              component={(props) => (
                <EditVideo
                  {...props}
                  videos={this.state.videos}
                  fetchData={this.fetchVideos}
                />
              )}
            />

            {/*  */}

            <Route
              exact
              path='/displacements'
              component={(props) => (
                <Displacements
                  {...props}
                  data={this.state.displacements}
                  fetchData={this.fetchDisplacements}
                />
              )}
            />
            <Route
              exact
              path='/displacements/:id'
              component={(props) => (
                <DisplacementDetail
                  {...props}
                  data={this.state.displacements}
                  fetchData={this.fetchDisplacements}
                />
              )}
            />
            <Route
              exact
              path='/displacements/edit/:id'
              component={(props) => (
                <EditDisplacement
                  {...props}
                  data={this.state.displacements}
                  fetchData={this.fetchDisplacements}
                />
              )}
            />
            {/*  */}

            <Route
              exact
              path='/orphans'
              component={(props) => (
                <Orphans
                  {...props}
                  data={this.state.orphans}
                  fetchData={this.fetchOrphans}
                />
              )}
            />
            <Route
              exact
              path='/orphans/:id'
              component={(props) => (
                <OrphanDetail
                  {...props}
                  data={this.state.orphans}
                  fetchData={this.fetchOrphans}
                />
              )}
            />
            <Route
              exact
              path='/orphans/edit/:id'
              component={(props) => (
                <EditOrphan
                  {...props}
                  data={this.state.orphans}
                  fetchData={this.fetchOrphans}
                />
              )}
            />

            {/*  */}

            <Route
              exact
              path='/links'
              component={(props) => (
                <LinkURLs
                  {...props}
                  data={this.state.linkURLs}
                  fetchData={this.fetchLinkURLs}
                />
              )}
            />
            <Route
              exact
              path='/links/:id'
              component={(props) => (
                <LinkURLDetail
                  {...props}
                  data={this.state.linkURLs}
                  fetchData={this.fetchLinkURLs}
                />
              )}
            />
            <Route
              exact
              path='/links/edit/:id'
              component={(props) => (
                <EditLinkURL
                  {...props}
                  data={this.state.linkURLs}
                  fetchData={this.fetchLinkURLs}
                />
              )}
            />
            {/*  */}

            <Route
              exact
              path='/missings'
              component={(props) => (
                <Missings
                  {...props}
                  data={this.state.missings}
                  fetchData={this.fetchMissings}
                />
              )}
            />
            <Route
              exact
              path='/missings/:id'
              component={(props) => (
                <MissingDetail
                  {...props}
                  data={this.state.missings}
                  fetchData={this.fetchMissings}
                />
              )}
            />
            <Route
              exact
              path='/missings/edit/:id'
              component={(props) => (
                <EditMissing
                  {...props}
                  data={this.state.missings}
                  fetchData={this.fetchMissings}
                />
              )}
            />

            <PrivateRoute
              exact
              path='/dashboard'
              component={(props) => (
                <Admin
                  {...props}
                  fetchVictims={this.fetchVictims}
                  fetchLinkURLs={this.fetchLinkURLs}
                  fetchMassacres={this.fetchMassacres}
                  fetchVideos={this.fetchVideos}
                  fetchOrphans={this.fetchOrphans}
                  fetchMissings={this.fetchMissings}
                  fetchDisplacements={this.fetchDisplacements}
                />
              )}
            />

            <Route
              exact
              path='/user/activation/:id'
              component={(props) => <UserActivation {...props} />}
            />
            <Route exact path='/signup' component={(props) => <SignUp />} />
            <Route
              exact
              path='/signin'
              component={(props) => <SignIn title='Sign in to your account' />}
            />
            <Route
              exact
              path='/forgotpassword'
              component={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path='/resetpassword/:id'
              component={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path='/activation-message'
              component={(props) => <ActivationEmailMessage {...props} />}
            />
            {/* <PrivateRoute exact path='/users' component={Users} /> */}

            <Route
              exact
              path='/check-email'
              component={(props) => <CheckYourEmail {...props} />}
            />
            <Route exact path='/reports' component={Reports} />
            <Route exact path='/' component={Home} images={this.state.images} />

            <Route
              exact
              path='/events'
              component={(props) => (
                <Events
                  {...props}
                  events={this.state.events}
                  fetchData={this.fetchEvents}
                />
              )}
            />
            <Route
              exact
              path='/events/:id'
              component={(props) => (
                <EventDetail
                  {...props}
                  events={this.state.events}
                  fetchData={this.fetchEvents}
                />
              )}
            />
            <PrivateRoute
              exact
              path='/events/edit/:id'
              component={(props) => (
                <EditEvent
                  {...props}
                  events={this.state.events}
                  fetchData={this.fetchEvents}
                />
              )}
            />

          </Switch>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default App
