import React from 'react'
import { NavLink } from 'react-router-dom'
import LinkURL from './LinkURL'
import PropTypes from 'prop-types'

import Spinner from '../Spinner'
import Layout from '../Layout'
import educationImage from '../../assets/images/education.jpg'

const LinkURLs = (props) => {
  let renderContent
  if (props.data.length) {
    renderContent = props.data.map((linkURL, i) => (
      <div key={linkURL._id} className='students'>
        <NavLink to={`/links/${linkURL._id}`}>
          <LinkURL linkURL={linkURL} />
        </NavLink>
      </div>
    ))
  } else if (props.data.length === 0) {
    renderContent = <div>No blogs found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Links: ' + props.data.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}

LinkURLs.propTypes = {}

export default LinkURLs
