import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import Status from '../shared/Status'
import ShowActions, { isAdmin } from '../shared/ShowActions'
import displacementImage from '../../assets/images/displaced_amhara.jpg'
import ApproveButton from '../shared/ApproveButton'
import BlogStat from '../shared/BlogStat'
import SocialShareButton from '../shared/SocialShareButton'

const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({ missing, auth, approveMissing, deleteMissing, id }) => {
  const { isAuthenticated, user } = auth
  const userId = user.id
  return (
    <div className='single-student'>
      <div className='profile-header'>
        <div className='profile-category' style={{ textAlign: 'center' }}>
          {/* <h4 className='card-title'>{missing.title}</h4>
          <small className='card-subtitle'>{missing.subtitle}</small>
          <br />
          <small className='card-date'>
            Published on <i className='far fa-clock'></i>{' '}
            {moment(missing.createdAt).format('ll')}
          </small>
          <small className='card-date'>
            Happened on{' '}
            <strong>{moment(missing.happenedOn).format('ll HH:mm:ss')}</strong>
          </small>
          <Status approved={missing.approved} /> */}
          <BlogStat item={missing} />
          <ApproveButton auth={auth} item={missing} onClick={approveMissing} />
        </div>
        {missing.imageUrl.length > 1 ? (
          <img
            src={missing.imageUrl}
            style={imageStyles}
            className='blog-image'
            alt={missing.title}
          />
        ) : (
          <img
            src={displacementImage}
            style={imageStyles}
            className='blog-image'
            alt={missing.title}
          />
        )}
      </div>
      <div className='profile-body'>
        <div className='profile-category'>
          <div className='description'>
            {<ReactMarkdown source={missing.description} />}
          </div>
        </div>
      </div>
      <div className='profile-footer'>
        <div>
          <small className='card-date'>
            <i className='far fa-clock'></i> Published on{' '}
            {moment(missing.createdAt).format('ll')}{' '}
          </small>
        </div>

        <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton category={'missings'} id={missing._id} />
        </div>

        <ShowActions
          isAuthenticated={isAuthenticated}
          auth={auth}
          item={missing}
          deleteItem={deleteMissing}
          name='missings'
        />
      </div>
    </div>
  )
}
const MissingDetail = (props) => {
  const { auth } = props
  const deleteMissing = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/missings/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/missings')
  }
  const approveMissing = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/missings/approve/${id}`
    axios
      .put(url)
      .then((response) => {})
      .catch((err) => {})
  }

  let renderContent
  let item

  if (props.data.length > 0) {
    const missing = props.data.find((evt) => evt._id == props.match.params.id)
    item = missing
    renderContent = (
      <Details
        missing={missing}
        auth={auth}
        approveMissing={approveMissing}
        deleteMissing={deleteMissing}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout
      title={item && item.title}
      imageUrl={item && item.imageUrl}
      category='missings'
      id={item && item._id}
    >
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

MissingDetail.propTypes = {}
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(MissingDetail)
