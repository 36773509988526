import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactMarkdown from 'react-markdown/with-html'
import Status from '../shared/Status'
import orphanImage from '../../assets/images/orphaned_2.png'
import ApproveButton from '../shared/ApproveButton'
import BlogStat from '../shared/BlogStat'

const Orphan = (props) => {
  if (Object.keys(props.orphan).length < 1) {
    return <h1>Loading...</h1>
  }

  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <>
      <div className='student'>
        <div className='card'>
          {props.orphan.imageUrl.length > 1 ? (
            <img
              src={props.orphan.imageUrl}
              style={imageStyles}
              className='card-image'
              alt={props.orphan.title}
            />
          ) : (
            <img
              src={orphanImage}
              style={imageStyles}
              className='card-image'
              alt={props.orphan.title}
            />
          )}
          <div>
            {/* <h2 className='card-title'>{props.orphan.title}</h2>
            <small className='card-subtitle'>{props.orphan.subtitle}</small>
            <br />

            <small className='card-date'>
              Published on <i className='far fa-clock'></i>{' '}
              {moment(props.orphan.createdAt).format('ll')}{' '}
            </small>
            <Status approved={props.orphan.approved} /> */}
            <BlogStat item={props.orphan} />

            <div>
              {props.orphan.description && (
                <ReactMarkdown
                  source={props.orphan.description.slice(0, 150)}
                />
              )}
              <br />
              <small className='read-more'>Read More</small>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Orphan
