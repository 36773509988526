import React from 'react'
import Navbar from './navbar/Navbar'
import PropTypes from 'prop-types'

const Header = ({ title }) => {
  return (
    <header>
      <div className='header-wrapper'>
        <Navbar />
      </div>
    </header>
  )
}

Header.propTypes = {}

export default Header
