import React from 'react'
import { NavLink } from 'react-router-dom'
import Event from './Event'
import PropTypes from 'prop-types'
import Header from '../Header'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Events = (props) => {
  let renderContent

  if (props.events.length) {
    renderContent = props.events.map((event, i) => (
      <div key={event._id} className='students'>
        <NavLink to={`/events/${event._id}`}>
          <Event event={event} />
        </NavLink>
      </div>
    ))
  }  else if(props.events.length!=0){

renderContent = <Spinner />
  }
  
  else {
    renderContent = <h1>No event</h1>
  }

  return (
    <Layout title={'Events: ' + props.events.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}
Events.propTypes = {}

export default Events
