import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import FileInputField from '../shared/FileInputField'


const AddEvent = (props) => {
  const intitalState = {
    title: '',
    subtitle: '',
    description: '',
    country: '',
    city: '',
    streetName: '',
    createdAt: '',
    startAt: '',
    endAt: '',
    image: '',
    files:[]
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(intitalState)
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type == 'file') {
      setFormData({ ...formData,files:[...e.target.files]})
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const url = '/api/v1.0.0/events'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      if (key ==='files'){
         formData[key].forEach((file) => data.append(`files[]`, file));
      }
      data.append(key, formData[key])
    }

    try {
      await axios({
        method: 'post',
        url: url,
        data,
      })
    } catch (error) {
      setErrors(error.response.data)
    }

    // props.fetchData()
    window.location.href = '/events'
  }

  const {
    title,
    subtitle,
    description,
    country,
    streetName,
    createdAt,
    startAt,
    endAt,
    city,
    image,
    
  } = errors

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className='row col-2'>
        <TextInputField
          label='Event Title'
          id='title'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          error={title}
        />
        <TextInputField
          label='Event Subtitle'
          id='subtitle'
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='News Subtitle'
          error={subtitle}
        />
        <div></div>
        
      </div>

      <div className='row'>
        <TextInputField
          label='Country'
          id='country'
          type='text'
          name='country'
          value={formData.country}
          onChange={onChange}
          placeholder='Country'
          error={country}
        />
        <TextInputField
          label='City'
          id='city'
          type='text'
          name='city'
          value={formData.city}
          onChange={onChange}
          placeholder='City'
          error={city}
        />
        <TextInputField
          label='Street Name'
          id='streetName'
          type='text'
          name='streetName'
          value={formData.streetName}
          onChange={onChange}
          placeholder='Event streetName'
          error={streetName}
        />
      </div>
      <div className='row'>
      <TextInputField
          label='Event Starts'
          type='datetime-local'
          id='startAt'
          name='startAt'
          value={formData.startAt}
          onChange={onChange}
          error={startAt}
        />
         <TextInputField
          label='Event Ends'
          type='datetime-local'
          id='endAt'
          name='endAt'
          value={formData.endAt}
          onChange={onChange}
          error={endAt}
        />
         <TextInputField
          label='Event Published'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={formData.createdAt}
          onChange={onChange}
          error={createdAt}
        />
      </div>

     

      <TextAreaField
        label='Description'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Your post  goes here ...'
        error={description}
      />
       {/* <FileInputField
        onChange={onChange}
        formData={formData}
        // percentage={percentage}
        text='Upload Image'
      /> */}

      {
        /*
         <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          size='600'
        />
      </div>
        */
      }
       <div className='form-group file-upload'>
        <input
          type='file'
          name='files'
          id='files'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          multiple
          size='600'
          // className={styles.inputfile}
        />
         {/* <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload Image
          </label> */}
      </div>  
     

      <button className='btn' type='submit' id='submit-button'>
        {props.title}
      </button>
    </form>
  )
}

// AddStudent.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default AddEvent
