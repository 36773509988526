import React from 'react'
import { Link } from 'react-router-dom'
import Layout from './Layout'

import displacedAmhara from '../assets/images/displaced_amhara.jpg'
import amharaMassacre from '../assets/images/massacre.jpg'
import orphanImage from '../assets/images/orphaned_2.png'
import missingImage from '../assets/images/missing.jpg'
import victimImage from '../assets/images/victim.webp'
import videoImage from '../assets/images/video.png'
import galleryImage2 from '../assets/images/gallery_2.png'
import amharagenocideAwarenessBanner from '../assets/images/stop_amhara_genocide_awareness_banner.jpg'
import AddSubscriber from './AddReport'

import linkImage from '../assets/images/media_link.jpg'
const colors = ['#796363', '#C2571A', '#D3B53D']
const index = Math.floor(Math.random() * colors.length)
const bgColor = colors[index]

const categories = [
  {
    category: 'Atrocities',
    amharicText: 'የተጨፈጨፉ',
    text: '',
    link: '/massacres',
    icon: '<i class="fas fa-landmark"></i>',
    image: amharaMassacre,
  },
  {
    category: 'Displacement',
    amharicText: 'የተፈናቀሉ',
    text: '',
    link: '/displacements',
    icon: '<i class="fas fa-history"></i>',
    image: displacedAmhara,
  },

  /*
  {
    category: 'Orphan',
    amharicText: 'ወላጃ አልባ የሆኑ',
    text: '',
    link: '/orphans',
    icon: '<i class="fas fa-coins"></i>',
    image: orphanImage,
  },
  {
    category: 'Missing',
    amharicText: 'የታፈኑ',
    text: 'TClick to watch videos',
    link: '/missings',
    icon: '<i class="fab fa-youtube"></i>',
    image: missingImage,
  },
   {
    category: 'Victims',
    amharicText: 'የተገደሉ',
    text: '',
    link: '/victims',
    icon: '<i class="fas fa-landmark"></i>',
    image: victimImage,
  }
  */,
  {
    category: 'Images',
    amharicText: 'ፎቶዎች',
    text: 'Click and go to gallery section',
    link: '/images',
    icon: '<i class="fas fa-images"></i>',
    image: galleryImage2,
  },
  {
    category: 'Videos',
    amharicText: 'ቪዲዮዎች',
    text: '',
    link: '/videos',
    icon: '<i class="fas fa-book-reader"></i>',
    image: videoImage,
  },
  {
    category: 'Media',
    amharicText: 'ሚዲያዎች',
    text: '',
    link: '/links',
    icon: '<i class="fas fa-book-reader"></i>',
    image: linkImage,
  },
]

const Home = (props) => {
  return (
    <Layout>
      <div>
        <h1
          className='main-title'
        >
         Repository of Amhara massacre victims
        </h1>
        <h1
          className='amharict-title-1'
          style={{ textAlign: 'center', padding: 0, margin: 0 }}
        >
          የአማራ ህዝብን ከጥፋት ለማዳን የበኩልዎን ይወጡ !!!
        </h1>
        <h6 style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          የተረጋገጠ መረጃ ወደ ድረ ገጹ በማስገባት የአማራና የአገው ህዝቦችን ጭፍጨፋ ይሰንዱ።
        </h6>
      </div>
      {/* <div className='hero'>
        <div className='hero-item hero-image'>
          <img src={dispacedAmhara} alt='Displaced Amhara' />
        </div>

        <div className='hero-item hero-text'>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae,
            officia doloribus non natus qui, nemo explicabo illum possimus
            aliquid delectus neque quae consectetur totam? Iste necessitatibus
            suscipit consectetur illum placeat. Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Maxime, a! Sapiente non esse unde
            facilis officiis, inventore, optio assumenda, architecto beatae sed
            reprehenderit explicabo. Pariatur ea magnam non distinctio
            similique. Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Quod alias fugit perspiciatis nesciunt architecto, vero praesentium
            natus, mollitia ea placeat quis reiciendis iure, illum dolore beatae
            consequuntur error quo atque!
          </p>
        </div>
      </div> */}
      <section>
        {/* <h1 className='section-title category-title'>Categories</h1> */}
        <div className='categories'>
          {categories.map(
            ({ category, text, link, icon, image, amharicText }) => (
              <Link
                key={category}
                className={'category'}
                to={link}
                key={category}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                }}
              >
                <div>
                  {' '}
                  {/* <div dangerouslySetInnerHTML={{ __html: icon }}></div> */}
                  <div className='category-center'>
                    <h1>{category}</h1>
                    <h2>{amharicText}</h2>
                  </div>
                  {/* <small>{text}</small> */}
                </div>
              </Link>
            )
          )}
        </div>
      </section>
      <section className='info-section' style = {{backgroundColor:'#efefef',borderBottom:'5px solid red', borderRight:'5px solid red'}}>

    <div >
     <h2 style = {{color:'red', fontWeight:'600'}}>Disclaimer </h2>
    <p style = {{color:'black'}}>
    The contents of the fact sheet are collected from human rights institutions and mainstream and social media reports concerning sustained human rights abuses, ethnic cleansing, and genocide throughout Ethiopia against the people of Amhara. Editors exert their utmost effort and due diligence to discriminate unsound and unwarranted information. Yet, it is the responsibility of the consumers to further ascertain the information contained herein.
    </p>
    </div>
      </section>
      
      <section
        className='info-section'
        style={{
          background: bgColor,
        }}
      >
        <h2>የዚህ ድረ ገፅ ዋና አላማ</h2>
        <p>
          በኢትዮጵያ በተለይም  በአሩሲ፣ በባሌ፣ በሐረር፣ በወለጋ፣ በአሶሳ፣ በመተክል  እየተፈጸመ ያለውን ዘርን መሰረት ያደረገ ፍጅት በአግባቡ ለመሰነድ በውጭ በሚኖሩ በጎ ፈቃደኛ ኢትዮጵያውያን የተዘጋጀ ድረ ገጽ ተጠናቅቆ በሙከራ ላይ ይገኛል።
          የድረ ገጹ ዋና አላማዎችም መረጃዎችን በተደራጀ መልኩ መሰነድ፣ ለአለም አቀፍ ተቋማትና ለሚመለከታቸው ሁሉ
          ማድረስ፣ ለታሪክና ለፍርድ ሂደቶች ማስቀመጥ፣ እንዲሁም ፍጅቶቹን በማስቆም ጥረት የበኩሉን አስተዋጽኦ ማበርከት
          ነው። እርስዎም ለድረ ገጹ ትክክለኛና የተጣሩ ማስረጃዎችን በመሰብሰብ እንዲሁም በእጅዎ የሚገኙ የጽሁፍ፣
          የድምጽና የምስል ማስረጃዎችን ወደ መረጃ ቋቱ በማስገባት የዚህ ጥረት ተካፋይ እንዲሆኑ ጥሪ ተላልፎልዎታል።
          <br />
          <strong>እናመሰግናለን!!!</strong>
        </p>
        <h2> The purpose of the website</h2>
        <p>
          This website is dedicated to document the ongoing genocide against Amhara in Ethiopia
          particularly in the Oromo and Benishangul Gumuz  regions. The main
          purposes of this website are to properly organize evidences of
          genocides, provide to international organizations and stakeholders,
          archive for future tribunals, and contribute to efforts to stop the
          ongoing ethnic cleansing and massacres in Ethiopia. You are invited to
          participate by submitting verified evidence in texts, photos, audios
          and videos at your hand into the database.
        </p>
      </section>

      <AddSubscriber />
      <section className='info-section'>
        <article>
          <h2>Related sites</h2>
          <div>
            <ul className='related-sites-list'>
              <li>
                <a
                  href='http://crimewatch.ethioportal.org/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {' '}
                  Genocide Prevention in Ethiopia
                </a>
              </li>
              <li>
                <a
                  href='https://sageethiopia.org/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {' '}
                  Society Against Genocide in Ethiopia
                </a>
              </li>
              <li>
                <a
                  href='https://www.genocidewatch.com/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Genocide Watch
                </a>
              </li>
              <li>
                <a
                  href=' https://www.amnesty.org/en/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Amnesty
                </a>
              </li>
              <li>
                <a
                  href='https://www.un.org/en/genocideprevention/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  UN Genocide Preventation and The Responsibility To Protect
                </a>
              </li>
              <li>
                <a
                  href='https://humanrightsconnected.org/organizations/ethiopian-human-rights-commission-ehrc/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Ethiopian Human Rights Commission
                </a>
              </li>
              <li>
                <a
                  href=' https://www.hrw.org/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Human Rights Watch
                </a>
              </li>
            </ul>
          </div>
        </article>
      </section>
      <section className='info-section'>
      <article>
      <h2>Stop Amhara Genocide Campaign Kit</h2>
      <p>Here is how you can help
by clicking on the image below you can order the right campaign gadget for you and create awareness around you about the Amhara Genocide in Ethiopia</p>
      
<div>
<a
                  href=' https://www.redbubble.com/shop/ap/74275357?ref=studio-promote'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  
                <img src = {amharagenocideAwarenessBanner} alt = "Amhara Genocide" />
                </a>
</div>

      </article>
       
      </section>
  
    </Layout>
  )
}

Home.propTypes = {}

export default Home
