import React from 'react'
import styles from './input-file.module.scss'
import PropTypes from 'prop-types'
import ProgressBar from './ProgressBar'

const FileInputField = ({ onChange, formData, percentage, text, name }) => {
  let fileName =
    formData.image == '' || formData == undefined ? text : formData.image.name
  return (
    <div className='form-group file-upload'>
      <input
        className={styles.inputfile}
        type='file'
        name={name}
        id='image'
        onChange={onChange}
        placeholder='Upload an image'
        accept='image/*'
        accept='file_extension|audio/*|video/*|image/*|media_type'
        size='600'
      />
      <label htmlFor='image'>
        {' '}
        <i className='fas fa-upload'></i>
        {fileName}
      </label>
      {/* {formData.image && (
        <ProgressBar bgcolor={'#6a1b9a'} completed={percentage} />
      )} */}
    </div>
  )
}

FileInputField.propTypes = {}

export default FileInputField
