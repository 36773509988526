import React, {useState, useEffect, Fragment} from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import historyImage from '../../assets/images/history.jpg'
import renderHTML from 'react-render-html'
import Status from '../shared/Status'
import ShowActions, { isAdmin } from '../shared/ShowActions'
import ApproveButton from '../shared/ApproveButton'
import BlogStat from '../shared/BlogStat'
import SocialShareButton from '../shared/SocialShareButton'
import massacreImage from '../../assets/images/massacre.jpg'

const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({ massacre, auth, approveMassacre, deleteMassacre, deleteMassacreImage, changeMassacreAccess, id }) => {
  const { isAuthenticated, user } = auth
  const userId = user.id
   const [imageIndex , setImageIndex] = useState(0)
    const images = massacre.images
    
    let lock = massacre.public  ? "fas fa-lock-open": "fas fa-lock"
    const nextImage = () => {
      if(imageIndex < images.length){
        setImageIndex(imageIndex + 1)
      } else if (imageIndex == images.length && imageIndex > 0) {
 setImageIndex(imageIndex - 1)
      }
    }
     const previousImage = () => {
    if (imageIndex < images.length && imageIndex !== 0) {
      let previousIndex = imageIndex - 1
      setImageIndex(previousIndex)
    }
  }

  useEffect(() =>{
    if(!isAuthenticated && !massacre.public){
     window.location = '/massacres'
    }
  })



  return (
    <div className='single-student'>
      <div className='profile-header details-header-banner'>
        <div className='profile-category' style={{ textAlign: 'center' }}>
          {/* <h4 className='card-title'>{massacre.title}</h4>
          <small className='card-subtitle'>{massacre.subtitle}</small>
          <br />
          <small className='card-date'>
            Published on <i className='far fa-clock'></i>{' '}
            {moment(massacre.createdAt).format('ll')}
          </small> */}
          <BlogStat item={massacre} />
          <ApproveButton
            auth={auth}
            item={massacre}
            onClick={approveMassacre}
          />
        </div>
        {/* <Status approved={massacre.approved} /> */}
      <div className='gallery-image-full-size'>
      {
        images.length > 0  && images[imageIndex] ? 
        (
        <Fragment>
          {imageIndex > 0 && (
            <div className='gallery-arrow order-2'>
            <i
              className='fas fa-chevron-circle-left'
              onClick={previousImage}
            ></i>
             </div>
          )}
        <div className='single-img-full-support order-1'>
        <div  className ="image-count">
        {imageIndex + 1}/{images.length}
       </div>
             {isAuthenticated && (
        <div className='icons'>
          <i 
          onClick = {() => deleteMassacreImage (images[imageIndex].imageId)}
            className={`btn-danger far fa-trash-alt`} style = {{color:'red', cursor:'pointer'}}
          
          ></i>
           <i 
          onClick = {() => changeMassacreAccess (massacre._id)}
            className={`btn-danger ${lock}`} style = {{color:'red', cursor:'pointer'}}
          ></i>
        </div>
      )}
         <img
          src={images[imageIndex].imageUrl}
          style={imageStyles}
          className='blog-image'
          loading='lazy'
          alt={massacre.title}/>
        </div>
        </Fragment>
        ) : (
        <div className='single-img-full order-1'>
            <img src={massacreImage} className='' alt={massacre.title} />{' '}
          </div>
      )}
          {imageIndex + 1 !== images.length && (
            <div className='gallery-arrow'>
            <i className='fas fa-chevron-circle-right' onClick={nextImage}></i>
            </div>
          )}
    </div>
      </div>

      <div className='profile-body'>
        <div className='profile-category'>
          <div className='description'>
            {<ReactMarkdown source={massacre.description} />}
          </div>
          {/* <div className='description-image'>
            {images.length > 1 && (
              <img
                src={images[imageIndex].imageUrl}
                style={imageStyles}
                className='blog-image'
                alt={massacre.title}
              />
            )}
          </div> */}
        </div>
      </div>

      <div className='profile-footer'>
        <div>
          <small className='card-date'>
            <i className='far fa-clock'></i> Published on{' '}
            {moment(massacre.createdAt).format('ll')}{' '}
          </small>
        </div>

        <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton category={'massacres'} id={massacre._id} />
        </div>
        <ShowActions
          isAuthenticated={isAuthenticated}
          auth={auth}
          item={massacre}
          deleteItem={deleteMassacre}
          name='massacres'
        />
      </div>
    </div>
  )
}
const MassacreDetail = (props) => {
  const { auth } = props
  const deleteMassacre = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/massacres/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/massacres')
  }
  const approveMassacre = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/massacres/approve/${id}`
    axios
      .put(url)
      .then((response) => {})
      .catch((err) => {})
  }
    const deleteMassacreImage = (imageId) => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/massacres/${id}/image/${imageId}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
  }
  const changeMassacreAccess = (id) => {
    const url = `/api/v1.0.0/massacres/${id}/access`
     axios.put(url).then((response) => {
      return
    })
    props.fetchData()
  }

  let renderContent
  let item

  if (props.data.length > 0) {
    const massacre = props.data.find((st) => st._id == props.match.params.id)
    item = massacre
    renderContent = (
      <Details
        massacre={massacre}
        auth={auth}
        approveMassacre={approveMassacre}
        deleteMassacre={deleteMassacre}
        deleteMassacreImage = {deleteMassacreImage}
        changeMassacreAccess = {changeMassacreAccess}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout
      title={item && item.title}
      imageUrl={item && item.imageUrl}
      category='massacres'
      id={item && item._id}
    >
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(MassacreDetail)
