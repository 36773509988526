import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import victimImage from '../../assets/images/victim.webp'
import Status from '../shared/Status'
import BlogStat from '../shared/BlogStat'

const Victim = ({ victim }) => {
  let latestPost =
    (new Date().getDate() - new Date(victim.createdAt).getDate()) * -1
  if (Object.keys(victim).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }
  const cardBorderStyle = {
    // borderRight: latestPost < 1 ? '2px solid red' : '',
  }

  return (
    <>
      <div className='student'>
        <div className='card' style={cardBorderStyle}>
          {victim.imageUrl.length > 1 ? (
            <img
              src={victim.imageUrl}
              style={imageStyles}
              className='blog-card-image'
              alt={victim.title}
            />
          ) : (
            <img
              src={victimImage}
              style={imageStyles}
              className='blog-card-image'
              alt={victim.title}
            />
          )}

          <div>
            {/* <h2 className='card-title'>{victim.title}</h2>
            <small className='card-subtitle'>{victim.subtitle} </small>
            <small className='card-date'>
              Published on <i className='far fa-clock'></i>{' '}
              {moment(victim.createdAt).format('ll')}{' '}
            </small>
            <Status approved={victim.approved} />
            <div className='profile-category'>
              <h4>{victim.fullName}</h4>
              <small>{victim.location} </small>
            </div> */}
            <BlogStat item={victim} />

            <div>
              {victim.description && (
                <ReactMarkdown source={victim.description.slice(0, 250)} />
              )}
              <small className='read-more'>Read More</small>
            </div>
          </div>
          {/* 
          {latestPost < 3 && (
            <div>
              <div className='post-badge'>NEW</div>
            </div>
          )} */}
        </div>
      </div>
    </>
  )
}

Victim.propTypes = {}

export default Victim
