import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Header from '../Header'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import SelectListGroup from '../shared/SelectListGroup'
import { countries } from '../../utils/countries'
import { showDateTime } from '../../utils/show_day_time'
import FileInputField from '../shared/FileInputField'
import styles from '../shared/input-file.module.scss'
import moment from 'moment'

const EditEvent = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    description: '',
    country: '',
    city: '',
    streetName: '',
    createdAt: '',
    startAt: '',
    endAt: '',
    time: '',
    image: '',
    files:[],
  }
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const url = `/api/v1.0.0/events`
    axios
      .get(url)
      .then((response) => {
        const id = props.match.params.id
        const event = response.data.find((st) => st._id == id)

        const {
          title,
          subtitle,
          description,
          country,
          city,
          streetName,
          createdAt,
           startAt,
            endAt,
        } = event

        const data = {
          title,
          subtitle,
          country,
          city,
          streetName,
          createdAt,
          startAt,
          endAt,
          description,
        }
        setFormData(data)
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
   
    if (type == 'file') {
      setFormData({ ...formData,files:[...e.target.files]})
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/events/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
   const data = new FormData()
    for (const key in formData) {
      if (key ==='files'){
         formData[key].forEach((file) => data.append(`files[]`, file));
      }
      data.append(key, formData[key])
    }

    axios({
      method: 'put',
      url: url,
      data
    }).then((response) => {})
    props.fetchData()
    props.history.push(`/events/${id}`)
  }

  const createdAtFormatted = moment(formData.createdAt).format('YYYY-MM-DDTHH:mm:ss')
  const startAtFormatted = moment(formData.startAt).format('YYYY-MM-DDTHH:mm:ss')
  const endAtFormatted = moment(formData.endAt).format('YYYY-MM-DDTHH:mm:ss')

  const {
    title,
    subtitle,
    description,
    time,
    country,
    createdAt,
    startAt,
    endAt,
    city,
    streetName,
  } = errors

  return (
    <Layout title='Edit Event'>
      <form onSubmit={onSubmit} noValidate>
        <div className='row col-2'>
          <TextInputField
            label='Event Title'
            id='title'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />
          <TextInputField
            label='Event Subtitle'
            id='subtitle'
            type='text'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='News Subtitle'
            error={subtitle}
          />
         <div></div>
        </div>

        <div className='row'>
          <TextInputField
            label='Country'
            id='country'
            type='text'
            name='country'
            value={formData.country}
            onChange={onChange}
            placeholder='Country'
            error={country}
          />
          <TextInputField
            label='City'
            id='city'
            type='text'
            name='city'
            value={formData.city}
            onChange={onChange}
            placeholder='City'
            error={city}
          />
          <TextInputField
            label='Street Name'
            id='streetName'
            type='text'
            name='streetName'
            value={formData.streetName}
            onChange={onChange}
            placeholder='Event streetName'
            error={streetName}
          />
        </div>
         <div className='row'>
      <TextInputField
          label='Event Starts'
          type='datetime-local'
          id='startAt'
          name='startAt'
          value={startAtFormatted}
          onChange={onChange}
          error={startAt}
        />
         <TextInputField
          label='Event Ends'
          type='datetime-local'
          id='endAt'
          name='endAt'
          value={endAtFormatted}
          onChange={onChange}
          error={endAt}
        />
         <TextInputField
          label='Event Published'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={createdAtFormatted}
          onChange={onChange}
          error={createdAt}
        />
      </div>

        <TextAreaField
          label='Description'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Your blog post content goes here ...'
          error={description}
        />

        {
          /**
           * 
           * 
            <FileInputField
        onChange={onChange}
        formData={formData}
        // percentage={percentage}
        text='Upload Image'
      />
           */
        }

     
         {/* <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
            multiple
            className={styles.inputfile}
          />
          <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload Image
          </label>
        </div> */}
          <div className='form-group file-upload'>
        <input
          type='file'
          name='files'
          id='files'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          multiple
          size='600'
          // className={styles.inputfile}
        />
         {/* <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload Image
          </label> */}
      </div>  

        <button className='btn' type='submit' id='submit-button'>
          Update
        </button>
      </form>
    </Layout>
  )
}

EditEvent.propTypes = {}

export default EditEvent
