import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Header from '../Header'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import SelectListGroup from '../shared/SelectListGroup'
import { countries } from '../../utils/countries'
import { showDateTime } from '../../utils/show_day_time'
import moment from 'moment'
import styles from '../shared/input-file.module.scss'

const EditVideo = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    location: '',
    description: '',
    happenedOn: '',
    createdAt: '',
    image: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const url = `/api/v1.0.0/videos`
    axios
      .get(url)
      .then((response) => {
        const id = props.match.params.id
        const video = response.data.find((st) => st._id == id)
        const { title, subtitle, happenedOn, createdAt, description } = video

        const data = {
          title,
          subtitle,
          happenedOn,
          createdAt,
          location,
          description,
        }
        setFormData(data)
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/videos/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const imageData = new FormData()
    for (const key in formData) {
      imageData.append(key, formData[key])
    }

    axios({
      method: 'put',
      url: url,
      data: imageData,
    }).then((response) => {})
    props.fetchData()
   setTimeout(() => {
      props.history.push(`/videos/${id}`)
   }, 3000)
   


  }

  const createdAtFormatted = moment(formData.createdAt).format(
    'YYYY-MM-DDTHH:mm:ss'
  )
  const happenedOnFormatted = moment(formData.happenedOn).format(
    'YYYY-MM-DDTHH:mm:ss'
  )
  console.log(formData)
  const {
    title,
    subtitle,
    location,
    happenedOn,
    createdAt,
    description,
  } = errors
  return (
    <Layout title='Edit Video'>
      <form onSubmit={onSubmit} noValidate>
        <div className='row'>
          <TextInputField
            label='Title'
            id='title'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />

          <TextInputField
            label='Subtitle'
            id='subtitle'
            type='text'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='Subtitle'
            error={subtitle}
          />

          <TextInputField
            label='Location'
            id='location'
            type='text'
            name='location'
            value={formData.location}
            onChange={onChange}
            placeholder='Location'
            error={location}
          />
        </div>

        <div className='row'>
          <TextInputField
            label='Happened on'
            type='datetime-local'
            id='happendOn'
            name='happenedOn'
            value={happenedOnFormatted}
            onChange={onChange}
            error={happenedOn}
          />
          <TextInputField
            label='Published on'
            type='datetime-local'
            id='createdAt'
            name='createdAt'
            value={createdAtFormatted}
            onChange={onChange}
            error={createdAt}
          />
          <div></div>
        </div>

        <TextAreaField
          label='Description'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Your blog post content goes here ...'
          error={description}
        />

        <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload a video'
            accept='image/*'
            size='600'
            className={styles.inputfile}
          />
          <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload a Video
          </label>
        </div>

        <button className='btn' type='submit' id='submit-button'>
          Update
        </button>
      </form>
    </Layout>
  )
}

EditVideo.propTypes = {}

export default EditVideo
