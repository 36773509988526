import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import Status from '../shared/Status'
import ShowActions from '../shared/ShowActions'
import displacementImage from '../../assets/images/displaced_amhara.jpg'
import ApproveButton from '../shared/ApproveButton'
import BlogStat from '../shared/BlogStat'
import SocialShareButton from '../shared/SocialShareButton'

const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({
  displacement,
  auth,
  approveDisplacement,
  deleteDisplacement,
}) => {
  return (
    <div className='single-student'>
      <div className='profile-header'>
        <div className='profile-category' style={{ textAlign: 'center' }}>
          {/* <h4 className='card-title'>{displacement.title}</h4>
          <small className='card-subtitle'>{displacement.subtitle}</small>
          <br />
          <small className='card-date'>
            Published on <i className='far fa-clock'></i>{' '}
            {moment(displacement.createdAt).format('ll')}
          </small>
          <small className='card-date'>
            Happened on{' '}
            <strong>
              {moment(displacement.happenedOn).format('ll HH:mm:ss')}
            </strong>
          </small>
          <Status approved={displacement.approved} /> */}
          <BlogStat item={displacement} />
          <ApproveButton
            auth={auth}
            item={displacement}
            onClick={approveDisplacement}
          />
        </div>
        {displacement.imageUrl.length > 1 ? (
          <img
            src={displacement.imageUrl}
            style={imageStyles}
            className='blog-image'
            alt={displacement.title}
          />
        ) : (
          <img
            src={displacementImage}
            style={imageStyles}
            className='blog-image'
            alt={displacement.title}
          />
        )}
      </div>
      <div className='profile-body'>
        <div className='profile-category'>
          <div className='description'>
            {<ReactMarkdown source={displacement.description} />}
          </div>
        </div>
      </div>
      <div className='profile-footer'>
        <div>
          <small className='card-date'>
            <i className='far fa-clock'></i> Published on{' '}
            {moment(displacement.createdAt).format('ll')}{' '}
          </small>
        </div>
        <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton category={'displacements'} id={displacement._id} />
        </div>

        <ShowActions
          auth={auth}
          item={displacement}
          deleteItem={deleteDisplacement}
          name='displacements'
        />
      </div>
    </div>
  )
}
const DisplacementDetail = (props) => {
  const { auth } = props
  const deleteDisplacement = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/displacements/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/displacements')
  }
  const approveDisplacement = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/displacements/approve/${id}`
    axios
      .put(url)
      .then((response) => {})
      .catch((err) => {})
  }

  let renderContent
  let item

  if (props.data.length > 0) {
    const displacement = props.data.find(
      (evt) => evt._id == props.match.params.id
    )
    item = displacement
    renderContent = (
      <Details
        displacement={displacement}
        auth={auth}
        approveDisplacement={approveDisplacement}
        deleteDisplacement={deleteDisplacement}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout
      title={item && item.title}
      imageUrl={item && item.imageUrl}
      category='displacements'
      id={item && item._id}
    >
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

DisplacementDetail.propTypes = {}
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(DisplacementDetail)
