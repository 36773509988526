import React from 'react'
import PropTypes from 'prop-types'
import Layout from './Layout'

const About = (props) => {
  return (
    <Layout title=''>
      <div className='main-wrapper about'>
      {
        /**
         // <h1>
        //  AMHARA GENOCIDE
        // </h1>
         */
      }
       
        <section>
          <blockquote>
            <strong>
              {' '}
              “For the dead and the living, we must bear witness.” ― elie wiesel
            </strong>
          </blockquote>
          <div className='about-section gensis'>
            <strong style={{ color: 'red' }}>GENESIS:</strong> Ethiopia has been
            through ethnic-based federalism since the military overthrow of the
            Dergue regime in 1991 by the collective struggle of Ethiopians.
            Unprepared for a proper transition plan and political void, Ethiopia
            fell into the hands of Tigray People's Liberation Front (TPLF)
            initially struggling for the independence of Tigray. Soon after,
            Ethiopia was unlawfully and unjustly divided into ethnic regions. A
            tribal constitution was then deliberately introduced granting
            exclusive ownership of these regions to specific groups, opening
            Ethiopia for a “legalized” ethnic cleansing and genocide. Any sign
            of sympathy towards citizenship politics, national unity and a
            strong Ethiopia, purposefully labelled as Amhara assets, became
            un-parallel crimes in the nation. For the same reason, Ethnic Amharas
            have been the main targets of 30-years-long horrific killings,
            ethnic cleansing and displacement in the country.
          </div>
   
          <div className='about-section ongoing-massacre'>
            <strong style={{ color: 'red' }}> ONGOING MASSACRE: </strong>
            Amharas/Agews have been being killed on daily basis. People are being massacred in many parts of  Ethiopia such as in Oromo, 
            Benishangul Gumuz ,and SNNP regions. The killings get as gruesome
            as butchering body organ alive, throat slit with machete, and use of
            bows with poisoned spears. The perpetrators show no mercy to elders,
            to women, to newborns, and even to the unborns. The ongoing
            massacres against Amharas/Agews suffices all the 10 requirements of
            GENOCIDE based on international definitions. These are clearly
            crimes against humanity. Security and political elements of local
            and regional governments are repeatedly accused to be involved in
            supporting, coordinating and covering the massacres. The federal
            government of Ethiopia is not taking its responsibility to bring
            criminals to justice and safeguard innocent civilians.
          </div>
       
          <div className='about-section mission'>
            <strong style={{ color: 'red' }}>MISSION: </strong>
            <p> This website is dedicated to:</p>
            <ul>
              <li>
                Collect, organize, document, and avail records of ongoing and
                past genocidal records against Amharas/Agews in Ethiopia
              </li>
              <li>
                Aware and alert national and international human rights
                organizations on a regular basis
              </li>
              <li>
                Serve as a database for valid record of events and evidences of
                genocide for national and international tribunals in the future
              </li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  )
}

About.propTypes = {}

export default About
