import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import './assets/styles/normalize.css'
import './assets/styles/common.scss'
import './assets/styles/header.scss'
import './assets/styles/footer.scss'
import './assets/styles/about.scss'
import './assets/styles/buttons.css'
import './assets/styles/icons.scss'
import './assets/styles/form.scss'
import './assets/styles/main.scss'
import './assets/styles/report.scss'
import './assets/styles/image-detail.scss'
import './assets/styles/table.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'

ReactDOM.render(<App />, document.querySelector('.wrapper'))
