//Register User
import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

export const signUp = (userData, history) => {
  return dispatch => {
    axios
      .post("/api/users/signup", userData)
      .then(res => {
        history.push('/activation-message')
        console.log(res.data);
      })
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };
};

export const signIn = userData => {
  return dispatch => {
    axios
      .post("/api/users/signin", userData)
      .then(res => {
        //Save to local storage
        const { token } = res.data;
        //set token to localstorage
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        //Decode token to get user data
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
      })
      .catch(err => {
        let payload = err.response && err.response.data
        dispatch({ type: GET_ERRORS, payload });
      });
  };
};

//Set logged in user;
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const signOut = () => {
  console.log('hi')
  return dispatch => {
    //Remove oken from localstore;
    localStorage.removeItem("jwtToken");
    //Remove auth header for future requests;
    setAuthToken(false);
    //Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
  };
};
