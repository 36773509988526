import React from 'react'
import { NavLink } from 'react-router-dom'
import Missing from './Missing'
import PropTypes from 'prop-types'
import Header from '../Header'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Missings = (props) => {
  let renderContent

  if (props.data.length > 0) {
    renderContent = props.data.map((missing, i) => (
      <div key={missing._id} className='students'>
        <NavLink to={`/missings/${missing._id}`}>
          <Missing missing={missing} />
        </NavLink>
      </div>
    ))
  } else if (props.data.length == 0) {
    renderContent = <p>No data has been found</p>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Missing ' + props.data.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}

export default Missings
