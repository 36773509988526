import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactMarkdown from 'react-markdown/with-html'
import Status from '../shared/Status'
import displacementImage from '../../assets/images/displaced_amhara.jpg'
import ApproveButton from '../shared/ApproveButton'
import BlogStat from '../shared/BlogStat'

const Displacement = (props) => {
  if (Object.keys(props.displacement).length < 1) {
    return <h1>Loading...</h1>
  }

  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <>
      <div className='student'>
        <div className='card'>
          {props.displacement.imageUrl.length > 1 ? (
            <img
              src={props.displacement.imageUrl}
              style={imageStyles}
              className='card-image'
              alt={props.displacement.title}
            />
          ) : (
            <img
              src={displacementImage}
              style={imageStyles}
              className='card-image'
              alt={props.displacement.title}
            />
          )}
          <div>
            <BlogStat item={props.displacement} />
            {/* <h2 className='card-title'>{props.displacement.title}</h2>
            <small className='card-subtitle'>
              {props.displacement.subtitle}
            </small>
            <br />

            <small className='card-date'>
              Published on <i className='far fa-clock'></i>{' '}
              {moment(props.displacement.createdAt).format('ll')}{' '}
            </small>
            <Status approved={props.displacement.approved} /> */}
            {/* <ApproveButton
              auth={auth}
              item={props.displacement}
              onClick={props.approveDisplacement}
            /> */}

            <div>
              {props.displacement.description && (
                <ReactMarkdown
                  source={props.displacement.description.slice(0, 150)}
                />
              )}
              <br />
              <small className='read-more'>Read More</small>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Displacement
