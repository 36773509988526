import React from 'react'
import { NavLink } from 'react-router-dom'
import Displacement from './Displacement'
import PropTypes from 'prop-types'
import Header from '../Header'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Displacements = (props) => {
  let renderContent

  if (props.data.length > 0) {
    renderContent = props.data.map((displacement, i) => (
      <div key={displacement._id} className='students'>
        <NavLink to={`/displacements/${displacement._id}`}>
          <Displacement displacement={displacement} />
        </NavLink>
      </div>
    ))
  } else if (props.data.length == 0) {
    renderContent = <p>No data has been found</p>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Displaced People  ' + props.data.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}

export default Displacements
