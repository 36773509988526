import React from "react";
import PropTypes from "prop-types";
import spinner from "./spinner.gif";

const Spinner = props => {
  return (
    <div>
      <img src={spinner} alt="Loading..."
        style={{
          width:200, margin:'auto', display:'block'
        }}/>
    </div>
  );
};

Spinner.propTypes = {};

export default Spinner;
