export const addresses  = [
    {
    country:'canada',
    message:'Mess for canada',
    provinces: {
    "Alberta": [
        {
            "name": "Damien Kurek",
            "email": "Damien.Kurek@parl.gc.ca",
            "district": "Battle River-Crowfoot"
        },
        {
            "name": "Martin Shields",
            "email": "Martin.Shields@parl.gc.ca",
            "district": "Bow River"
        },
        {
            "name": "Greg McLean",
            "email": "Greg.McLean@parl.gc.ca",
            "district": "Calgary Centre"
        },
        {
            "name": "Len Webber",
            "email": "Len.Webber@parl.gc.ca",
            "district": "Calgary Confederation"
        },
        {
            "name": "Jasraj Singh Hallan",
            "email": "Jasraj Singh.Hallan@parl.gc.ca",
            "district": "Calgary Forest Lawn"
        },
        {
            "name": "Bob Benzen",
            "email": "Bob.Benzen@parl.gc.ca",
            "district": "Calgary Heritage"
        },
        {
            "name": "Stephanie Kusie",
            "email": "Stephanie.Kusie@parl.gc.ca",
            "district": "Calgary Midnapore"
        },
        {
            "name": "Hon. Michelle Rempel Garner",
            "email": "Michelle.Rempel Garner@parl.gc.ca",
            "district": "Calgary Nose Hill"
        },
        {
            "name": "Pat Kelly",
            "email": "Pat.Kelly@parl.gc.ca",
            "district": "Calgary Rocky Ridge"
        },
        {
            "name": "Tom Kmiec",
            "email": "Tom.Kmiec@parl.gc.ca",
            "district": "Calgary Shepard"
        },
        {
            "name": "Ron Liepert",
            "email": "Ron.Liepert@parl.gc.ca",
            "district": "Calgary Signal Hill"
        },
        {
            "name": "Jag Sahota",
            "email": "Jag.Sahota@parl.gc.ca",
            "district": "Calgary Skyview"
        },
        {
            "name": "James Cumming",
            "email": "James.Cumming@parl.gc.ca",
            "district": "Edmonton Centre"
        },
        {
            "name": "Kerry Diotte",
            "email": "Kerry.Diotte@parl.gc.ca",
            "district": "Edmonton Griesbach"
        },
        {
            "name": "Ziad Aboultaif",
            "email": "Ziad.Aboultaif@parl.gc.ca",
            "district": "Edmonton Manning"
        },
        {
            "name": "Hon. Tim Uppal",
            "email": "Tim.Uppal@parl.gc.ca",
            "district": "Edmonton Mill Woods"
        },
        {
            "name": "Matt Jeneroux",
            "email": "Matt.Jeneroux@parl.gc.ca",
            "district": "Edmonton Riverbend"
        },
        {
            "name": "Heather McPherson",
            "email": "Heather.McPherson@parl.gc.ca",
            "district": "Edmonton Strathcona"
        },
        {
            "name": "Kelly McCauley",
            "email": "Kelly.McCauley@parl.gc.ca",
            "district": "Edmonton West"
        },
        {
            "name": "Hon. Mike Lake",
            "email": "Mike.Lake@parl.gc.ca",
            "district": "Edmonton-Wetaskiwin"
        },
        {
            "name": "John Barlow",
            "email": "John.Barlow@parl.gc.ca",
            "district": "Foothills"
        },
        {
            "name": "David Yurdiga",
            "email": "David.Yurdiga@parl.gc.ca",
            "district": "Fort McMurray-Cold Lake"
        },
        {
            "name": "Chris Warkentin",
            "email": "Chris.Warkentin@parl.gc.ca",
            "district": "Grande Prairie-Mackenzie"
        },
        {
            "name": "Shannon Stubbs",
            "email": "Shannon.Stubbs@parl.gc.ca",
            "district": "Lakeland"
        },
        {
            "name": "Rachael Harder",
            "email": "Rachael.Harder@parl.gc.ca",
            "district": "Lethbridge"
        },
        {
            "name": "Glen Motz",
            "email": "Glen.Motz@parl.gc.ca",
            "district": "Medicine Hat-Cardston-Warner"
        },
        {
            "name": "Arnold Viersen",
            "email": "Arnold.Viersen@parl.gc.ca",
            "district": "Peace River-Westlock"
        },
        {
            "name": "Blaine Calkins",
            "email": "Blaine.Calkins@parl.gc.ca",
            "district": "Red Deer-Lacombe"
        },
        {
            "name": "Earl Dreeshen",
            "email": "Earl.Dreeshen@parl.gc.ca",
            "district": "Red Deer-Mountain View"
        },
        {
            "name": "Garnett Genuis",
            "email": "Garnett.Genuis@parl.gc.ca",
            "district": "Sherwood Park-Fort Saskatchewan"
        },
        {
            "name": "Michael Cooper",
            "email": "Michael.Cooper@parl.gc.ca",
            "district": "St. Albert-Edmonton"
        },
        {
            "name": "Dane Lloyd",
            "email": "Dane.Lloyd@parl.gc.ca",
            "district": "Sturgeon River-Parkland"
        },
        {
            "name": "Gerald Soroka",
            "email": "Gerald.Soroka@parl.gc.ca",
            "district": "Yellowhead"
        }
    ],
    "British Columbia": [
        {
            "name": "Hon. Ed Fast",
            "email": "Ed.Fast@parl.gc.ca",
            "district": "Abbotsford"
        },
        {
            "name": "Terry Beech",
            "email": "Terry.Beech@parl.gc.ca",
            "district": "Burnaby North-Seymour"
        },
        {
            "name": "Jagmeet Singh",
            "email": "Jagmeet.Singh@parl.gc.ca",
            "district": "Burnaby South"
        },
        {
            "name": "Todd Doherty",
            "email": "Todd.Doherty@parl.gc.ca",
            "district": "Cariboo-Prince George"
        },
        {
            "name": "Dan Albas",
            "email": "Dan.Albas@parl.gc.ca",
            "district": "Central Okanagan-Similkameen-Nicola"
        },
        {
            "name": "Mark Strahl",
            "email": "Mark.Strahl@parl.gc.ca",
            "district": "Chilliwack-Hope"
        },
        {
            "name": "Tamara Jansen",
            "email": "Tamara.Jansen@parl.gc.ca",
            "district": "Cloverdale-Langley City"
        },
        {
            "name": "Ron McKinnon",
            "email": "Ron.McKinnon@parl.gc.ca",
            "district": "Coquitlam-Port Coquitlam"
        },
        {
            "name": "Gord Johns",
            "email": "Gord.Johns@parl.gc.ca",
            "district": "Courtenay-Alberni"
        },
        {
            "name": "Alistair MacGregor",
            "email": "Alistair.MacGregor@parl.gc.ca",
            "district": "Cowichan-Malahat-Langford"
        },
        {
            "name": "Hon. Carla Qualtrough",
            "email": "Carla.Qualtrough@parl.gc.ca",
            "district": "Delta"
        },
        {
            "name": "Randall Garrison",
            "email": "Randall.Garrison@parl.gc.ca",
            "district": "Esquimalt-Saanich-Sooke"
        },
        {
            "name": "Ken Hardie",
            "email": "Ken.Hardie@parl.gc.ca",
            "district": "Fleetwood-Port Kells"
        },
        {
            "name": "Cathy McLeod",
            "email": "Cathy.McLeod@parl.gc.ca",
            "district": "Kamloops-Thompson-Cariboo"
        },
        {
            "name": "Tracy Gray",
            "email": "Tracy.Gray@parl.gc.ca",
            "district": "Kelowna-Lake Country"
        },
        {
            "name": "Rob Morrison",
            "email": "Rob.Morrison@parl.gc.ca",
            "district": "Kootenay-Columbia"
        },
        {
            "name": "Tako Van Popta",
            "email": "Tako.Van Popta@parl.gc.ca",
            "district": "Langley-Aldergrove"
        },
        {
            "name": "Brad Vis",
            "email": "Brad.Vis@parl.gc.ca",
            "district": "Mission-Matsqui-Fraser Canyon"
        },
        {
            "name": "Paul Manly",
            "email": "Paul.Manly@parl.gc.ca",
            "district": "Nanaimo-Ladysmith"
        },
        {
            "name": "Peter Julian",
            "email": "Peter.Julian@parl.gc.ca",
            "district": "New Westminster-Burnaby"
        },
        {
            "name": "Rachel Blaney",
            "email": "Rachel.Blaney@parl.gc.ca",
            "district": "North Island-Powell River"
        },
        {
            "name": "Mel Arnold",
            "email": "Mel.Arnold@parl.gc.ca",
            "district": "North Okanagan-Shuswap"
        },
        {
            "name": "Hon. Jonathan Wilkinson",
            "email": "Jonathan.Wilkinson@parl.gc.ca",
            "district": "North Vancouver"
        },
        {
            "name": "Marc Dalton",
            "email": "Marc.Dalton@parl.gc.ca",
            "district": "Pitt Meadows-Maple Ridge"
        },
        {
            "name": "Nelly Shin",
            "email": "Nelly.Shin@parl.gc.ca",
            "district": "Port Moody-Coquitlam"
        },
        {
            "name": "Bob Zimmer",
            "email": "Bob.Zimmer@parl.gc.ca",
            "district": "Prince George-Peace River-Northern Rockies"
        },
        {
            "name": "Hon. Alice Wong",
            "email": "Alice.Wong@parl.gc.ca",
            "district": "Richmond Centre"
        },
        {
            "name": "Elizabeth May",
            "email": "Elizabeth.May@parl.gc.ca",
            "district": "Saanich-Gulf Islands"
        },
        {
            "name": "Taylor Bachrach",
            "email": "Taylor.Bachrach@parl.gc.ca",
            "district": "Skeena-Bulkley Valley"
        },
        {
            "name": "Richard Cannings",
            "email": "Richard.Cannings@parl.gc.ca",
            "district": "South Okanagan-West Kootenay"
        },
        {
            "name": "Hon. Kerry-Lynne Findlay",
            "email": "Kerry-Lynne.Findlay@parl.gc.ca",
            "district": "South Surrey-White Rock"
        },
        {
            "name": "Kenny Chiu",
            "email": "Kenny.Chiu@parl.gc.ca",
            "district": "Steveston-Richmond East"
        },
        {
            "name": "Randeep Sarai",
            "email": "Randeep.Sarai@parl.gc.ca",
            "district": "Surrey Centre"
        },
        {
            "name": "Sukh Dhaliwal",
            "email": "Sukh.Dhaliwal@parl.gc.ca",
            "district": "Surrey-Newton"
        },
        {
            "name": "Hon. Hedy Fry",
            "email": "Hedy.Fry@parl.gc.ca",
            "district": "Vancouver Centre"
        },
        {
            "name": "Jenny Kwan",
            "email": "Jenny.Kwan@parl.gc.ca",
            "district": "Vancouver East"
        },
        {
            "name": "Hon. Jody Wilson-Raybould",
            "email": "Jody.Wilson-Raybould@parl.gc.ca",
            "district": "Vancouver Granville"
        },
        {
            "name": "Don Davies",
            "email": "Don.Davies@parl.gc.ca",
            "district": "Vancouver Kingsway"
        },
        {
            "name": "Hon. Joyce Murray",
            "email": "Joyce.Murray@parl.gc.ca",
            "district": "Vancouver Quadra"
        },
        {
            "name": "Hon. Harjit S. Sajjan",
            "email": "Harjit S..Sajjan@parl.gc.ca",
            "district": "Vancouver South"
        },
        {
            "name": "Laurel Collins",
            "email": "Laurel.Collins@parl.gc.ca",
            "district": "Victoria"
        },
        {
            "name": "Patrick Weiler",
            "email": "Patrick.Weiler@parl.gc.ca",
            "district": "West Vancouver-Sunshine Coast-Sea to Sky Country"
        }
    ],
    "Manitoba": [
        {
            "name": "Larry Maguire",
            "email": "Larry.Maguire@parl.gc.ca",
            "district": "Brandon-Souris"
        },
        {
            "name": "Marty Morantz",
            "email": "Marty.Morantz@parl.gc.ca",
            "district": "Charleswood-St. James-Assiniboia-Headingley"
        },
        {
            "name": "Niki Ashton",
            "email": "Niki.Ashton@parl.gc.ca",
            "district": "Churchill-Keewatinook Aski"
        },
        {
            "name": "Dan Mazier",
            "email": "Dan.Mazier@parl.gc.ca",
            "district": "Dauphin-Swan River-Neepawa"
        },
        {
            "name": "Daniel Blaikie",
            "email": "Daniel.Blaikie@parl.gc.ca",
            "district": "Elmwood-Transcona"
        },
        {
            "name": "Raquel Dancho",
            "email": "Raquel.Dancho@parl.gc.ca",
            "district": "Kildonan-St. Paul"
        },
        {
            "name": "Hon. Candice Bergen",
            "email": "Candice.Bergen@parl.gc.ca",
            "district": "Portage-Lisgar"
        },
        {
            "name": "Ted Falk",
            "email": "Ted.Falk@parl.gc.ca",
            "district": "Provencher"
        },
        {
            "name": "Hon. Dan Vandal",
            "email": "Dan.Vandal@parl.gc.ca",
            "district": "Saint Boniface-Saint Vital"
        },
        {
            "name": "James Bezan",
            "email": "James.Bezan@parl.gc.ca",
            "district": "Selkirk-Interlake-Eastman"
        },
        {
            "name": "Leah Gazan",
            "email": "Leah.Gazan@parl.gc.ca",
            "district": "Winnipeg Centre"
        },
        {
            "name": "Kevin Lamoureux",
            "email": "Kevin.Lamoureux@parl.gc.ca",
            "district": "Winnipeg North"
        },
        {
            "name": "Terry Duguid",
            "email": "Terry.Duguid@parl.gc.ca",
            "district": "Winnipeg South"
        },
        {
            "name": "Hon. Jim Carr",
            "email": "Jim.Carr@parl.gc.ca",
            "district": "Winnipeg South Centre"
        }
    ],
    "New Brunswick": [
        {
            "name": "Serge Cormier",
            "email": "Serge.Cormier@parl.gc.ca",
            "district": "Acadie-Bathurst"
        },
        {
            "name": "Hon. Dominic LeBlanc",
            "email": "Dominic.LeBlanc@parl.gc.ca",
            "district": "Beauséjour"
        },
        {
            "name": "Jenica Atwin",
            "email": "Jenica.Atwin@parl.gc.ca",
            "district": "Fredericton"
        },
        {
            "name": "Hon. Rob Moore",
            "email": "Rob.Moore@parl.gc.ca",
            "district": "Fundy Royal"
        },
        {
            "name": "René Arseneault",
            "email": "René.Arseneault@parl.gc.ca",
            "district": "Madawaska-Restigouche"
        },
        {
            "name": "Pat Finnigan",
            "email": "Pat.Finnigan@parl.gc.ca",
            "district": "Miramichi-Grand Lake"
        },
        {
            "name": "Hon. Ginette Petitpas Taylor",
            "email": "Ginette.Petitpas Taylor@parl.gc.ca",
            "district": "Moncton-Riverview-Dieppe"
        },
        {
            "name": "John Williamson",
            "email": "John.Williamson@parl.gc.ca",
            "district": "New Brunswick Southwest"
        },
        {
            "name": "Wayne Long",
            "email": "Wayne.Long@parl.gc.ca",
            "district": "Saint John-Rothesay"
        },
        {
            "name": "Richard Bragdon",
            "email": "Richard.Bragdon@parl.gc.ca",
            "district": "Tobique-Mactaquac"
        }
    ],
    "Newfoundland and Labrador": [
        {
            "name": "Ken McDonald",
            "email": "Ken.McDonald@parl.gc.ca",
            "district": "Avalon"
        },
        {
            "name": "Churence Rogers",
            "email": "Churence.Rogers@parl.gc.ca",
            "district": "Bonavista-Burin-Trinity"
        },
        {
            "name": "Scott Simms",
            "email": "Scott.Simms@parl.gc.ca",
            "district": "Coast of Bays-Central-Notre Dame"
        },
        {
            "name": "Yvonne Jones",
            "email": "Yvonne.Jones@parl.gc.ca",
            "district": "Labrador"
        },
        {
            "name": "Gudie Hutchings",
            "email": "Gudie.Hutchings@parl.gc.ca",
            "district": "Long Range Mountains"
        },
        {
            "name": "Jack Harris",
            "email": "Jack.Harris@parl.gc.ca",
            "district": "St. John's East"
        },
        {
            "name": "Hon. Seamus O'Regan",
            "email": "Seamus.O'Regan@parl.gc.ca",
            "district": "St. John's South-Mount Pearl"
        }
    ],
    "Northwest Territories": [
        {
            "name": "Michael McLeod",
            "email": "Michael.McLeod@parl.gc.ca",
            "district": "Northwest Territories"
        }
    ],
    "Nova Scotia": [
        {
            "name": "Mike Kelloway",
            "email": "Mike.Kelloway@parl.gc.ca",
            "district": "Cape Breton-Canso"
        },
        {
            "name": "Sean Fraser",
            "email": "Sean.Fraser@parl.gc.ca",
            "district": "Central Nova"
        },
        {
            "name": "Lenore Zann",
            "email": "Lenore.Zann@parl.gc.ca",
            "district": "Cumberland-Colchester"
        },
        {
            "name": "Darren Fisher",
            "email": "Darren.Fisher@parl.gc.ca",
            "district": "Dartmouth-Cole Harbour"
        },
        {
            "name": "Andy Fillmore",
            "email": "Andy.Fillmore@parl.gc.ca",
            "district": "Halifax"
        },
        {
            "name": "Hon. Geoff Regan",
            "email": "Geoff.Regan@parl.gc.ca",
            "district": "Halifax West"
        },
        {
            "name": "Kody Blois",
            "email": "Kody.Blois@parl.gc.ca",
            "district": "Kings-Hants"
        },
        {
            "name": "Darrell Samson",
            "email": "Darrell.Samson@parl.gc.ca",
            "district": "Sackville-Preston-Chezzetcook"
        },
        {
            "name": "Hon. Bernadette Jordan",
            "email": "Bernadette.Jordan@parl.gc.ca",
            "district": "South Shore-St. Margarets"
        },
        {
            "name": "Jaime Battiste",
            "email": "Jaime.Battiste@parl.gc.ca",
            "district": "Sydney-Victoria"
        },
        {
            "name": "Chris d'Entremont",
            "email": "Chris.d'Entremont@parl.gc.ca",
            "district": "West Nova"
        }
    ],
    "Nunavut": [
        {
            "name": "Mumilaaq Qaqqaq",
            "email": "Mumilaaq.Qaqqaq@parl.gc.ca",
            "district": "Nunavut"
        }
    ],
    "Ontario": [
        {
            "name": "Hon. Mark Holland",
            "email": "Mark.Holland@parl.gc.ca",
            "district": "Ajax"
        },
        {
            "name": "Carol Hughes",
            "email": "Carol.Hughes@parl.gc.ca",
            "district": "Algoma-Manitoulin-Kapuskasing"
        },
        {
            "name": "Leona Alleslev",
            "email": "Leona.Alleslev@parl.gc.ca",
            "district": "Aurora-Oak Ridges-Richmond Hill"
        },
        {
            "name": "John Brassard",
            "email": "John.Brassard@parl.gc.ca",
            "district": "Barrie-Innisfil"
        },
        {
            "name": "Doug Shipley",
            "email": "Doug.Shipley@parl.gc.ca",
            "district": "Barrie-Springwater-Oro-Medonte"
        },
        {
            "name": "Neil Ellis",
            "email": "Neil.Ellis@parl.gc.ca",
            "district": "Bay of Quinte"
        },
        {
            "name": "Nathaniel Erskine-Smith",
            "email": "Nathaniel.Erskine-Smith@parl.gc.ca",
            "district": "Beaches-East York"
        },
        {
            "name": "Ramesh Sangha",
            "email": "Ramesh.Sangha@parl.gc.ca",
            "district": "Brampton Centre"
        },
        {
            "name": "Maninder Sidhu",
            "email": "Maninder.Sidhu@parl.gc.ca",
            "district": "Brampton East"
        },
        {
            "name": "Ruby Sahota",
            "email": "Ruby.Sahota@parl.gc.ca",
            "district": "Brampton North"
        },
        {
            "name": "Sonia Sidhu",
            "email": "Sonia.Sidhu@parl.gc.ca",
            "district": "Brampton South"
        },
        {
            "name": "Kamal Khera",
            "email": "Kamal.Khera@parl.gc.ca",
            "district": "Brampton West"
        },
        {
            "name": "Phil McColeman",
            "email": "Phil.McColeman@parl.gc.ca",
            "district": "Brantford-Brant"
        },
        {
            "name": "Alex Ruff",
            "email": "Alex.Ruff@parl.gc.ca",
            "district": "Bruce-Grey-Owen Sound"
        },
        {
            "name": "Hon. Karina Gould",
            "email": "Karina.Gould@parl.gc.ca",
            "district": "Burlington"
        },
        {
            "name": "Bryan May",
            "email": "Bryan.May@parl.gc.ca",
            "district": "Cambridge"
        },
        {
            "name": "Hon. Pierre Poilievre",
            "email": "Pierre.Poilievre@parl.gc.ca",
            "district": "Carleton"
        },
        {
            "name": "Dave Epp",
            "email": "Dave.Epp@parl.gc.ca",
            "district": "Chatham-Kent-Leamington"
        },
        {
            "name": "Julie Dzerowicz",
            "email": "Julie.Dzerowicz@parl.gc.ca",
            "district": "Davenport"
        },
        {
            "name": "Yasmin Ratansi",
            "email": "Yasmin.Ratansi@parl.gc.ca",
            "district": "Don Valley East"
        },
        {
            "name": "Han Dong",
            "email": "Han.Dong@parl.gc.ca",
            "district": "Don Valley North"
        },
        {
            "name": "Robert Oliphant",
            "email": "Robert.Oliphant@parl.gc.ca",
            "district": "Don Valley West"
        },
        {
            "name": "Kyle Seeback",
            "email": "Kyle.Seeback@parl.gc.ca",
            "district": "Dufferin-Caledon"
        },
        {
            "name": "Hon. Erin O'Toole",
            "email": "Erin.O'Toole@parl.gc.ca",
            "district": "Durham"
        },
        {
            "name": "Hon. Marco Mendicino",
            "email": "Marco.Mendicino@parl.gc.ca",
            "district": "Eglinton-Lawrence"
        },
        {
            "name": "Karen Vecchio",
            "email": "Karen.Vecchio@parl.gc.ca",
            "district": "Elgin-Middlesex-London"
        },
        {
            "name": "Chris Lewis",
            "email": "Chris.Lewis@parl.gc.ca",
            "district": "Essex"
        },
        {
            "name": "Yvan Baker",
            "email": "Yvan.Baker@parl.gc.ca",
            "district": "Etobicoke Centre"
        },
        {
            "name": "Hon. Kirsty Duncan",
            "email": "Kirsty.Duncan@parl.gc.ca",
            "district": "Etobicoke North"
        },
        {
            "name": "James Maloney",
            "email": "James.Maloney@parl.gc.ca",
            "district": "Etobicoke-Lakeshore"
        },
        {
            "name": "David Sweet",
            "email": "David.Sweet@parl.gc.ca",
            "district": "Flamborough-Glanbrook"
        },
        {
            "name": "Francis Drouin",
            "email": "Francis.Drouin@parl.gc.ca",
            "district": "Glengarry-Prescott-Russell"
        },
        {
            "name": "Lloyd Longfield",
            "email": "Lloyd.Longfield@parl.gc.ca",
            "district": "Guelph"
        },
        {
            "name": "Hon. Diane Finley",
            "email": "Diane.Finley@parl.gc.ca",
            "district": "Haldimand-Norfolk"
        },
        {
            "name": "Jamie Schmale",
            "email": "Jamie.Schmale@parl.gc.ca",
            "district": "Haliburton-Kawartha Lakes-Brock"
        },
        {
            "name": "Matthew Green",
            "email": "Matthew.Green@parl.gc.ca",
            "district": "Hamilton Centre"
        },
        {
            "name": "Bob Bratina",
            "email": "Bob.Bratina@parl.gc.ca",
            "district": "Hamilton East-Stoney Creek"
        },
        {
            "name": "Scott Duvall",
            "email": "Scott.Duvall@parl.gc.ca",
            "district": "Hamilton Mountain"
        },
        {
            "name": "Hon. Filomena Tassi",
            "email": "Filomena.Tassi@parl.gc.ca",
            "district": "Hamilton West-Ancaster-Dundas"
        },
        {
            "name": "Derek Sloan",
            "email": "Derek.Sloan@parl.gc.ca",
            "district": "Hastings-Lennox and Addington"
        },
        {
            "name": "Hon. Judy A. Sgro",
            "email": "Judy A..Sgro@parl.gc.ca",
            "district": "Humber River-Black Creek"
        },
        {
            "name": "Ben Lobb",
            "email": "Ben.Lobb@parl.gc.ca",
            "district": "Huron-Bruce"
        },
        {
            "name": "Karen McCrimmon",
            "email": "Karen.McCrimmon@parl.gc.ca",
            "district": "Kanata-Carleton"
        },
        {
            "name": "Eric Melillo",
            "email": "Eric.Melillo@parl.gc.ca",
            "district": "Kenora"
        },
        {
            "name": "Mark Gerretsen",
            "email": "Mark.Gerretsen@parl.gc.ca",
            "district": "Kingston and the Islands"
        },
        {
            "name": "Hon. Deb Schulte",
            "email": "Deb.Schulte@parl.gc.ca",
            "district": "King-Vaughan"
        },
        {
            "name": "Raj Saini",
            "email": "Raj.Saini@parl.gc.ca",
            "district": "Kitchener Centre"
        },
        {
            "name": "Marwan Tabbara",
            "email": "Marwan.Tabbara@parl.gc.ca",
            "district": "Kitchener South-Hespeler"
        },
        {
            "name": "Tim Louis",
            "email": "Tim.Louis@parl.gc.ca",
            "district": "Kitchener-Conestoga"
        },
        {
            "name": "Lianne Rood",
            "email": "Lianne.Rood@parl.gc.ca",
            "district": "Lambton-Kent-Middlesex"
        },
        {
            "name": "Scott Reid",
            "email": "Scott.Reid@parl.gc.ca",
            "district": "Lanark-Frontenac-Kingston"
        },
        {
            "name": "Michael Barrett",
            "email": "Michael.Barrett@parl.gc.ca",
            "district": "Leeds-Grenville-Thousand Islands and Rideau Lakes"
        },
        {
            "name": "Peter Fragiskatos",
            "email": "Peter.Fragiskatos@parl.gc.ca",
            "district": "London North Centre"
        },
        {
            "name": "Kate Young",
            "email": "Kate.Young@parl.gc.ca",
            "district": "London West"
        },
        {
            "name": "Lindsay Mathyssen",
            "email": "Lindsay.Mathyssen@parl.gc.ca",
            "district": "London-Fanshawe"
        },
        {
            "name": "Helena Jaczek",
            "email": "Helena.Jaczek@parl.gc.ca",
            "district": "Markham-Stouffville"
        },
        {
            "name": "Hon. Mary Ng",
            "email": "Mary.Ng@parl.gc.ca",
            "district": "Markham-Thornhill"
        },
        {
            "name": "Bob Saroya",
            "email": "Bob.Saroya@parl.gc.ca",
            "district": "Markham-Unionville"
        },
        {
            "name": "Adam van Koeverden",
            "email": "Adam.van Koeverden@parl.gc.ca",
            "district": "Milton"
        },
        {
            "name": "Hon. Omar Alghabra",
            "email": "Omar.Alghabra@parl.gc.ca",
            "district": "Mississauga Centre"
        },
        {
            "name": "Peter Fonseca",
            "email": "Peter.Fonseca@parl.gc.ca",
            "district": "Mississauga East-Cooksville"
        },
        {
            "name": "Iqra Khalid",
            "email": "Iqra.Khalid@parl.gc.ca",
            "district": "Mississauga-Erin Mills"
        },
        {
            "name": "Sven Spengemann",
            "email": "Sven.Spengemann@parl.gc.ca",
            "district": "Mississauga-Lakeshore"
        },
        {
            "name": "Hon. Navdeep Bains",
            "email": "Navdeep.Bains@parl.gc.ca",
            "district": "Mississauga-Malton"
        },
        {
            "name": "Gagan Sikand",
            "email": "Gagan.Sikand@parl.gc.ca",
            "district": "Mississauga-Streetsville"
        },
        {
            "name": "Chandra Arya",
            "email": "Chandra.Arya@parl.gc.ca",
            "district": "Nepean"
        },
        {
            "name": "Tony Van Bynen",
            "email": "Tony.Van Bynen@parl.gc.ca",
            "district": "Newmarket-Aurora"
        },
        {
            "name": "Vance Badawey",
            "email": "Vance.Badawey@parl.gc.ca",
            "district": "Niagara Centre"
        },
        {
            "name": "Tony Baldinelli",
            "email": "Tony.Baldinelli@parl.gc.ca",
            "district": "Niagara Falls"
        },
        {
            "name": "Dean Allison",
            "email": "Dean.Allison@parl.gc.ca",
            "district": "Niagara West"
        },
        {
            "name": "Marc Serré",
            "email": "Marc.Serré@parl.gc.ca",
            "district": "Nickel Belt"
        },
        {
            "name": "Hon. Anthony Rota",
            "email": "Anthony.Rota@parl.gc.ca",
            "district": "Nipissing-Timiskaming"
        },
        {
            "name": "Philip Lawrence",
            "email": "Philip.Lawrence@parl.gc.ca",
            "district": "Northumberland-Peterborough South"
        },
        {
            "name": "Hon. Anita Anand",
            "email": "Anita.Anand@parl.gc.ca",
            "district": "Oakville"
        },
        {
            "name": "Pam Damoff",
            "email": "Pam.Damoff@parl.gc.ca",
            "district": "Oakville North-Burlington"
        },
        {
            "name": "Marie-France Lalonde",
            "email": "Marie-France.Lalonde@parl.gc.ca",
            "district": "Orléans"
        },
        {
            "name": "Colin Carrie",
            "email": "Colin.Carrie@parl.gc.ca",
            "district": "Oshawa"
        },
        {
            "name": "Hon. Catherine McKenna",
            "email": "Catherine.McKenna@parl.gc.ca",
            "district": "Ottawa Centre"
        },
        {
            "name": "Hon. David McGuinty",
            "email": "David.McGuinty@parl.gc.ca",
            "district": "Ottawa South"
        },
        {
            "name": "Anita Vandenbeld",
            "email": "Anita.Vandenbeld@parl.gc.ca",
            "district": "Ottawa West-Nepean"
        },
        {
            "name": "Hon. Mona Fortier",
            "email": "Mona.Fortier@parl.gc.ca",
            "district": "Ottawa-Vanier"
        },
        {
            "name": "Dave MacKenzie",
            "email": "Dave.MacKenzie@parl.gc.ca",
            "district": "Oxford"
        },
        {
            "name": "Arif Virani",
            "email": "Arif.Virani@parl.gc.ca",
            "district": "Parkdale-High Park"
        },
        {
            "name": "Scott Aitchison",
            "email": "Scott.Aitchison@parl.gc.ca",
            "district": "Parry Sound-Muskoka"
        },
        {
            "name": "John Nater",
            "email": "John.Nater@parl.gc.ca",
            "district": "Perth-Wellington"
        },
        {
            "name": "Hon. Maryam Monsef",
            "email": "Maryam.Monsef@parl.gc.ca",
            "district": "Peterborough-Kawartha"
        },
        {
            "name": "Jennifer O'Connell",
            "email": "Jennifer.O'Connell@parl.gc.ca",
            "district": "Pickering-Uxbridge"
        },
        {
            "name": "Cheryl Gallant",
            "email": "Cheryl.Gallant@parl.gc.ca",
            "district": "Renfrew-Nipissing-Pembroke"
        },
        {
            "name": "Majid Jowhari",
            "email": "Majid.Jowhari@parl.gc.ca",
            "district": "Richmond Hill"
        },
        {
            "name": "Marilyn Gladu",
            "email": "Marilyn.Gladu@parl.gc.ca",
            "district": "Sarnia-Lambton"
        },
        {
            "name": "Terry Sheehan",
            "email": "Terry.Sheehan@parl.gc.ca",
            "district": "Sault Ste. Marie"
        },
        {
            "name": "Salma Zahid",
            "email": "Salma.Zahid@parl.gc.ca",
            "district": "Scarborough Centre"
        },
        {
            "name": "Shaun Chen",
            "email": "Shaun.Chen@parl.gc.ca",
            "district": "Scarborough North"
        },
        {
            "name": "Hon. Bill Blair",
            "email": "Bill.Blair@parl.gc.ca",
            "district": "Scarborough Southwest"
        },
        {
            "name": "Jean Yip",
            "email": "Jean.Yip@parl.gc.ca",
            "district": "Scarborough-Agincourt"
        },
        {
            "name": "Hon. John McKay",
            "email": "John.McKay@parl.gc.ca",
            "district": "Scarborough-Guildwood"
        },
        {
            "name": "Gary Anandasangaree",
            "email": "Gary.Anandasangaree@parl.gc.ca",
            "district": "Scarborough-Rouge Park"
        },
        {
            "name": "Bruce Stanton",
            "email": "Bruce.Stanton@parl.gc.ca",
            "district": "Simcoe North"
        },
        {
            "name": "Terry Dowdall",
            "email": "Terry.Dowdall@parl.gc.ca",
            "district": "Simcoe-Grey"
        },
        {
            "name": "Adam Vaughan",
            "email": "Adam.Vaughan@parl.gc.ca",
            "district": "Spadina-Fort York"
        },
        {
            "name": "Chris Bittle",
            "email": "Chris.Bittle@parl.gc.ca",
            "district": "St. Catharines"
        },
        {
            "name": "Eric Duncan",
            "email": "Eric.Duncan@parl.gc.ca",
            "district": "Stormont-Dundas-South Glengarry"
        },
        {
            "name": "Paul Lefebvre",
            "email": "Paul.Lefebvre@parl.gc.ca",
            "district": "Sudbury"
        },
        {
            "name": "Hon. Peter Kent",
            "email": "Peter.Kent@parl.gc.ca",
            "district": "Thornhill"
        },
        {
            "name": "Marcus Powlowski",
            "email": "Marcus.Powlowski@parl.gc.ca",
            "district": "Thunder Bay-Rainy River"
        },
        {
            "name": "Hon. Patty Hajdu",
            "email": "Patty.Hajdu@parl.gc.ca",
            "district": "Thunder Bay-Superior North"
        },
        {
            "name": "Charlie Angus",
            "email": "Charlie.Angus@parl.gc.ca",
            "district": "Timmins-James Bay"
        },
        {
            "name": "Marci Ien",
            "email": "Marci.Ien@parl.gc.ca",
            "district": "Toronto Centre"
        },
        {
            "name": "Julie Dabrusin",
            "email": "Julie.Dabrusin@parl.gc.ca",
            "district": "Toronto-Danforth"
        },
        {
            "name": "Hon. Carolyn Bennett",
            "email": "Carolyn.Bennett@parl.gc.ca",
            "district": "Toronto-St. Paul's"
        },
        {
            "name": "Hon. Chrystia Freeland",
            "email": "Chrystia.Freeland@parl.gc.ca",
            "district": "University-Rosedale"
        },
        {
            "name": "Francesco Sorbara",
            "email": "Francesco.Sorbara@parl.gc.ca",
            "district": "Vaughan-Woodbridge"
        },
        {
            "name": "Hon. Bardish Chagger",
            "email": "Bardish.Chagger@parl.gc.ca",
            "district": "Waterloo"
        },
        {
            "name": "Hon. Michael Chong",
            "email": "Michael.Chong@parl.gc.ca",
            "district": "Wellington-Halton Hills"
        },
        {
            "name": "Ryan Turnbull",
            "email": "Ryan.Turnbull@parl.gc.ca",
            "district": "Whitby"
        },
        {
            "name": "Ali Ehsassi",
            "email": "Ali.Ehsassi@parl.gc.ca",
            "district": "Willowdale"
        },
        {
            "name": "Brian Masse",
            "email": "Brian.Masse@parl.gc.ca",
            "district": "Windsor West"
        },
        {
            "name": "Irek Kusmierczyk",
            "email": "Irek.Kusmierczyk@parl.gc.ca",
            "district": "Windsor-Tecumseh"
        },
        {
            "name": "Ya'ara Saks",
            "email": "Ya'ara.Saks@parl.gc.ca",
            "district": "York Centre"
        },
        {
            "name": "Hon. Ahmed Hussen",
            "email": "Ahmed.Hussen@parl.gc.ca",
            "district": "York South-Weston"
        },
        {
            "name": "Scot Davidson",
            "email": "Scot.Davidson@parl.gc.ca",
            "district": "York-Simcoe"
        }
    ],
    "Prince Edward Island": [
        {
            "name": "Hon. Lawrence MacAulay",
            "email": "Lawrence.MacAulay@parl.gc.ca",
            "district": "Cardigan"
        },
        {
            "name": "Sean Casey",
            "email": "Sean.Casey@parl.gc.ca",
            "district": "Charlottetown"
        },
        {
            "name": "Robert Morrissey",
            "email": "Robert.Morrissey@parl.gc.ca",
            "district": "Egmont"
        },
        {
            "name": "Hon. Wayne Easter",
            "email": "Wayne.Easter@parl.gc.ca",
            "district": "Malpeque"
        }
    ],
    "Quebec": [
        {
            "name": "Sylvie Bérubé",
            "email": "Sylvie.Bérubé@parl.gc.ca",
            "district": "Abitibi-Baie-James-Nunavik-Eeyou"
        },
        {
            "name": "Sébastien Lemire",
            "email": "Sébastien.Lemire@parl.gc.ca",
            "district": "Abitibi-Témiscamingue"
        },
        {
            "name": "Hon. Mélanie Joly",
            "email": "Mélanie.Joly@parl.gc.ca",
            "district": "Ahuntsic-Cartierville"
        },
        {
            "name": "Angelo Iacono",
            "email": "Angelo.Iacono@parl.gc.ca",
            "district": "Alfred-Pellan"
        },
        {
            "name": "Stéphane Lauzon",
            "email": "Stéphane.Lauzon@parl.gc.ca",
            "district": "Argenteuil-La Petite-Nation"
        },
        {
            "name": "Kristina Michaud",
            "email": "Kristina.Michaud@parl.gc.ca",
            "district": "Avignon-La Mitis-Matane-Matapédia"
        },
        {
            "name": "Richard Lehoux",
            "email": "Richard.Lehoux@parl.gc.ca",
            "district": "Beauce"
        },
        {
            "name": "Caroline Desbiens",
            "email": "Caroline.Desbiens@parl.gc.ca",
            "district": "Beauport-Côte-de-Beaupré-Île d'Orléans-Charlevoix"
        },
        {
            "name": "Julie Vignola",
            "email": "Julie.Vignola@parl.gc.ca",
            "district": "Beauport-Limoilou"
        },
        {
            "name": "Louis Plamondon",
            "email": "Louis.Plamondon@parl.gc.ca",
            "district": "Bécancour-Nicolet-Saurel"
        },
        {
            "name": "Hon. Steven Blaney",
            "email": "Steven.Blaney@parl.gc.ca",
            "district": "Bellechasse-Les Etchemins-Lévis"
        },
        {
            "name": "Yves-François Blanchet",
            "email": "Yves-François.Blanchet@parl.gc.ca",
            "district": "Beloeil-Chambly"
        },
        {
            "name": "Yves Perron",
            "email": "Yves.Perron@parl.gc.ca",
            "district": "Berthier-Maskinongé"
        },
        {
            "name": "Emmanuel Dubourg",
            "email": "Emmanuel.Dubourg@parl.gc.ca",
            "district": "Bourassa"
        },
        {
            "name": "Lyne Bessette",
            "email": "Lyne.Bessette@parl.gc.ca",
            "district": "Brome-Missisquoi"
        },
        {
            "name": "Alexandra Mendès",
            "email": "Alexandra.Mendès@parl.gc.ca",
            "district": "Brossard-Saint-Lambert"
        },
        {
            "name": "Pierre Paul-Hus",
            "email": "Pierre.Paul-Hus@parl.gc.ca",
            "district": "Charlesbourg-Haute-Saint-Charles"
        },
        {
            "name": "Brenda Shanahan",
            "email": "Brenda.Shanahan@parl.gc.ca",
            "district": "Châteauguay-Lacolle"
        },
        {
            "name": "Richard Martel",
            "email": "Richard.Martel@parl.gc.ca",
            "district": "Chicoutimi-Le Fjord"
        },
        {
            "name": "Hon. Marie-Claude Bibeau",
            "email": "Marie-Claude.Bibeau@parl.gc.ca",
            "district": "Compton-Stanstead"
        },
        {
            "name": "Anju Dhillon",
            "email": "Anju.Dhillon@parl.gc.ca",
            "district": "Dorval-Lachine-LaSalle"
        },
        {
            "name": "Martin Champoux",
            "email": "Martin.Champoux@parl.gc.ca",
            "district": "Drummond"
        },
        {
            "name": "Hon. Diane Lebouthillier",
            "email": "Diane.Lebouthillier@parl.gc.ca",
            "district": "Gaspésie-Les Îles-de-la-Madeleine"
        },
        {
            "name": "Steven MacKinnon",
            "email": "Steven.MacKinnon@parl.gc.ca",
            "district": "Gatineau"
        },
        {
            "name": "Soraya Martinez Ferrada",
            "email": "Soraya.Martinez Ferrada@parl.gc.ca",
            "district": "Hochelaga"
        },
        {
            "name": "Hon. Pablo Rodriguez",
            "email": "Pablo.Rodriguez@parl.gc.ca",
            "district": "Honoré-Mercier"
        },
        {
            "name": "Greg Fergus",
            "email": "Greg.Fergus@parl.gc.ca",
            "district": "Hull-Aylmer"
        },
        {
            "name": "Gabriel Ste-Marie",
            "email": "Gabriel.Ste-Marie@parl.gc.ca",
            "district": "Joliette"
        },
        {
            "name": "Mario Simard",
            "email": "Mario.Simard@parl.gc.ca",
            "district": "Jonquière"
        },
        {
            "name": "Mario Beaulieu",
            "email": "Mario.Beaulieu@parl.gc.ca",
            "district": "La Pointe-de-l'Île"
        },
        {
            "name": "Alain Therrien",
            "email": "Alain.Therrien@parl.gc.ca",
            "district": "La Prairie"
        },
        {
            "name": "Alexis Brunelle-Duceppe",
            "email": "Alexis.Brunelle-Duceppe@parl.gc.ca",
            "district": "Lac-Saint-Jean"
        },
        {
            "name": "Francis Scarpaleggia",
            "email": "Francis.Scarpaleggia@parl.gc.ca",
            "district": "Lac-Saint-Louis"
        },
        {
            "name": "Hon. David Lametti",
            "email": "David.Lametti@parl.gc.ca",
            "district": "LaSalle-Émard-Verdun"
        },
        {
            "name": "Marie-Hélène Gaudreau",
            "email": "Marie-Hélène.Gaudreau@parl.gc.ca",
            "district": "Laurentides-Labelle"
        },
        {
            "name": "Hon. Steven Guilbeault",
            "email": "Steven.Guilbeault@parl.gc.ca",
            "district": "Laurier-Sainte-Marie"
        },
        {
            "name": "Fayçal El-Khoury",
            "email": "Fayçal.El-Khoury@parl.gc.ca",
            "district": "Laval-Les Îles"
        },
        {
            "name": "Jacques Gourde",
            "email": "Jacques.Gourde@parl.gc.ca",
            "district": "Lévis-Lotbinière"
        },
        {
            "name": "Sherry Romanado",
            "email": "Sherry.Romanado@parl.gc.ca",
            "district": "Longueuil-Charles-LeMoyne"
        },
        {
            "name": "Denis Trudel",
            "email": "Denis.Trudel@parl.gc.ca",
            "district": "Longueuil-Saint-Hubert"
        },
        {
            "name": "Joël Lightbound",
            "email": "Joël.Lightbound@parl.gc.ca",
            "district": "Louis-Hébert"
        },
        {
            "name": "Gérard Deltell",
            "email": "Gérard.Deltell@parl.gc.ca",
            "district": "Louis-Saint-Laurent"
        },
        {
            "name": "Marilène Gill",
            "email": "Marilène.Gill@parl.gc.ca",
            "district": "Manicouagan"
        },
        {
            "name": "Yves Robillard",
            "email": "Yves.Robillard@parl.gc.ca",
            "district": "Marc-Aurèle-Fortin"
        },
        {
            "name": "Luc Berthold",
            "email": "Luc.Berthold@parl.gc.ca",
            "district": "Mégantic-L'Érable"
        },
        {
            "name": "Simon Marcil",
            "email": "Simon.Marcil@parl.gc.ca",
            "district": "Mirabel"
        },
        {
            "name": "Stéphane Bergeron",
            "email": "Stéphane.Bergeron@parl.gc.ca",
            "district": "Montarville"
        },
        {
            "name": "Luc Thériault",
            "email": "Luc.Thériault@parl.gc.ca",
            "district": "Montcalm"
        },
        {
            "name": "Bernard Généreux",
            "email": "Bernard.Généreux@parl.gc.ca",
            "district": "Montmagny-L'Islet-Kamouraska-Rivière-du-Loup"
        },
        {
            "name": "Anthony Housefather",
            "email": "Anthony.Housefather@parl.gc.ca",
            "district": "Mount Royal"
        },
        {
            "name": "Hon. Marc Garneau",
            "email": "Marc.Garneau@parl.gc.ca",
            "district": "Notre-Dame-de-Grâce-Westmount"
        },
        {
            "name": "Rachel Bendayan",
            "email": "Rachel.Bendayan@parl.gc.ca",
            "district": "Outremont"
        },
        {
            "name": "Right Hon. Justin Trudeau",
            "email": "Justin.Trudeau@parl.gc.ca",
            "district": "Papineau"
        },
        {
            "name": "Xavier Barsalou-Duval",
            "email": "Xavier.Barsalou-Duval@parl.gc.ca",
            "district": "Pierre-Boucher-Les Patriotes-Verchères"
        },
        {
            "name": "Sameer Zuberi",
            "email": "Sameer.Zuberi@parl.gc.ca",
            "district": "Pierrefonds-Dollard"
        },
        {
            "name": "William Amos",
            "email": "William.Amos@parl.gc.ca",
            "district": "Pontiac"
        },
        {
            "name": "Joël Godin",
            "email": "Joël.Godin@parl.gc.ca",
            "district": "Portneuf-Jacques-Cartier"
        },
        {
            "name": "Hon. Jean-Yves Duclos",
            "email": "Jean-Yves.Duclos@parl.gc.ca",
            "district": "Québec"
        },
        {
            "name": "Monique Pauzé",
            "email": "Monique.Pauzé@parl.gc.ca",
            "district": "Repentigny"
        },
        {
            "name": "Alain Rayes",
            "email": "Alain.Rayes@parl.gc.ca",
            "district": "Richmond-Arthabaska"
        },
        {
            "name": "Maxime Blanchette-Joncas",
            "email": "Maxime.Blanchette-Joncas@parl.gc.ca",
            "district": "Rimouski-Neigette-Témiscouata-Les Basques"
        },
        {
            "name": "Luc Desilets",
            "email": "Luc.Desilets@parl.gc.ca",
            "district": "Rivière-des-Mille-Îles"
        },
        {
            "name": "Rhéal Fortin",
            "email": "Rhéal.Fortin@parl.gc.ca",
            "district": "Rivière-du-Nord"
        },
        {
            "name": "Alexandre Boulerice",
            "email": "Alexandre.Boulerice@parl.gc.ca",
            "district": "Rosemont-La Petite-Patrie"
        },
        {
            "name": "Simon-Pierre Savard-Tremblay",
            "email": "Simon-Pierre.Savard-Tremblay@parl.gc.ca",
            "district": "Saint-Hyacinthe-Bagot"
        },
        {
            "name": "Christine Normandin",
            "email": "Christine.Normandin@parl.gc.ca",
            "district": "Saint-Jean"
        },
        {
            "name": "Emmanuella Lambropoulos",
            "email": "Emmanuella.Lambropoulos@parl.gc.ca",
            "district": "Saint-Laurent"
        },
        {
            "name": "Patricia Lattanzio",
            "email": "Patricia.Lattanzio@parl.gc.ca",
            "district": "Saint-Léonard-Saint-Michel"
        },
        {
            "name": "Hon. François-Philippe Champagne",
            "email": "François-Philippe.Champagne@parl.gc.ca",
            "district": "Saint-Maurice-Champlain"
        },
        {
            "name": "Claude DeBellefeuille",
            "email": "Claude.DeBellefeuille@parl.gc.ca",
            "district": "Salaberry-Suroît"
        },
        {
            "name": "Andréanne Larouche",
            "email": "Andréanne.Larouche@parl.gc.ca",
            "district": "Shefford"
        },
        {
            "name": "Élisabeth Brière",
            "email": "Élisabeth.Brière@parl.gc.ca",
            "district": "Sherbrooke"
        },
        {
            "name": "Michel Boudrias",
            "email": "Michel.Boudrias@parl.gc.ca",
            "district": "Terrebonne"
        },
        {
            "name": "Louise Chabot",
            "email": "Louise.Chabot@parl.gc.ca",
            "district": "Thérèse-De Blainville"
        },
        {
            "name": "Louise Charbonneau",
            "email": "Louise.Charbonneau@parl.gc.ca",
            "district": "Trois-Rivières"
        },
        {
            "name": "Peter Schiefke",
            "email": "Peter.Schiefke@parl.gc.ca",
            "district": "Vaudreuil-Soulanges"
        },
        {
            "name": "Hon. Marc Miller",
            "email": "Marc.Miller@parl.gc.ca",
            "district": "Ville-Marie-Le Sud-Ouest-Île-des-Soeurs"
        },
        {
            "name": "Annie Koutrakis",
            "email": "Annie.Koutrakis@parl.gc.ca",
            "district": "Vimy"
        }
    ],
    "Saskatchewan": [
        {
            "name": "Rosemarie Falk",
            "email": "Rosemarie.Falk@parl.gc.ca",
            "district": "Battlefords-Lloydminster"
        },
        {
            "name": "Kelly Block",
            "email": "Kelly.Block@parl.gc.ca",
            "district": "Carlton Trail-Eagle Creek"
        },
        {
            "name": "Jeremy Patzer",
            "email": "Jeremy.Patzer@parl.gc.ca",
            "district": "Cypress Hills-Grasslands"
        },
        {
            "name": "Gary Vidal",
            "email": "Gary.Vidal@parl.gc.ca",
            "district": "Desnethé-Missinippi-Churchill River"
        },
        {
            "name": "Tom Lukiwski",
            "email": "Tom.Lukiwski@parl.gc.ca",
            "district": "Moose Jaw-Lake Centre-Lanigan"
        },
        {
            "name": "Randy Hoback",
            "email": "Randy.Hoback@parl.gc.ca",
            "district": "Prince Albert"
        },
        {
            "name": "Warren Steinley",
            "email": "Warren.Steinley@parl.gc.ca",
            "district": "Regina-Lewvan"
        },
        {
            "name": "Hon. Andrew Scheer",
            "email": "Andrew.Scheer@parl.gc.ca",
            "district": "Regina-Qu'Appelle"
        },
        {
            "name": "Michael Kram",
            "email": "Michael.Kram@parl.gc.ca",
            "district": "Regina-Wascana"
        },
        {
            "name": "Brad Redekopp",
            "email": "Brad.Redekopp@parl.gc.ca",
            "district": "Saskatoon West"
        },
        {
            "name": "Kevin Waugh",
            "email": "Kevin.Waugh@parl.gc.ca",
            "district": "Saskatoon-Grasswood"
        },
        {
            "name": "Corey Tochor",
            "email": "Corey.Tochor@parl.gc.ca",
            "district": "Saskatoon-University"
        },
        {
            "name": "Robert Kitchen",
            "email": "Robert.Kitchen@parl.gc.ca",
            "district": "Souris-Moose Mountain"
        },
        {
            "name": "Cathay Wagantall",
            "email": "Cathay.Wagantall@parl.gc.ca",
            "district": "Yorkton-Melville"
        }
    ],
    "Yukon": [
        {
            "name": "Hon. Larry Bagnell",
            "email": "Larry.Bagnell@parl.gc.ca",
            "district": "Yukon"
        }
    ]
},
},
{
  "country": "Australia",
  "message": "Message from Australia",
  "provinces": {
    "Western Australia": [
        {
        "name": "Dr Anne Aly MP",
        "email": "Anne.Aly.MP@aph.gov.au",
        "district": "Cowan"
      },
      {
        "name": "Dr Anne Aly MP",
        "email": "Anne.Aly.MP@aph.gov.au",
        "district": "Cowan"
      },
      {
        "name": "Mr Vince Connelly MP",
        "email": "Vince.Connelly.MP@aph.gov.au",
        "district": "Stirling"
      },
      {
        "name": "Mr Ian Goodenough MP",
        "email": "",
        "district": "Moore"
      },
      {
        "name": "Mr Patrick Gorman MP",
        "email": "Patrick.Gorman.MP@aph.gov.au",
        "district": "Perth"
      },
      {
        "name": "Ms Celia Hammond MP",
        "email": "Celia.Hammond.MP@aph.gov.au",
        "district": "Curtin"
      },
      {
        "name": "Hon Andrew Hastie MP",
        "email": "Andrew.Hastie.MP@aph.gov.au",
        "district": "Canning"
      },
      {
        "name": "Hon Steve Irons MP",
        "email": "",
        "district": "Swan"
      },
      {
        "name": "Mr Matt Keogh MP",
        "email": "Matt.Keogh.MP@aph.gov.au",
        "district": "Burt"
      },
      {
        "name": "Ms Madeleine King MP",
        "email": "Madeleine.King.MP@aph.gov.au",
        "district": "Brand"
      },
      {
        "name": "Hon Nola Marino MP",
        "email": "Nola.Marino.MP@aph.gov.au",
        "district": "Forrest"
      },
      {
        "name": "Hon Ben Morton MP",
        "email": "Ben.Morton.MP@aph.gov.au",
        "district": "Tangney"
      },
      {
        "name": "Hon Christian Porter MP",
        "email": "",
        "district": "Pearce"
      },
      {
        "name": "Hon Melissa Price MP",
        "email": "Melissa.Price.MP@aph.gov.au",
        "district": "Durack"
      },
      {
        "name": "Mr Josh Wilson MP",
        "email": "Josh.Wilson.MP@aph.gov.au",
        "district": "Fremantle"
      },
      {
        "name": "Mr Rick Wilson MP",
        "email": "",
        "district": "O'Connor"
      },
      {
        "name": "Hon Ken Wyatt AM MP",
        "email": null,
        "district": "Hasluck"
      }
    ],
    "Northern Territory": [
      {
        "name": "Mr Luke Gosling OAM  MP",
        "email": "Luke.Gosling.MP@aph.gov.au",
        "district": "Solomon"
      },
      {
        "name": "Hon Warren Snowdon MP",
        "email": "Warren.Snowdon.MP@aph.gov.au",
        "district": "Lingiari"
      }
    ],
    "South Australia": [
      {
        "name": "Hon Mark Butler MP",
        "email": "",
        "district": "Hindmarsh"
      },
      {
        "name": "Mr Nick Champion MP",
        "email": "Nick.Champion.MP@aph.gov.au",
        "district": "Spence"
      },
      {
        "name": "Ms Nicolle Flint MP",
        "email": "Nicolle.Flint.MP@aph.gov.au",
        "district": "Boothby"
      },
      {
        "name": "Mr Steve Georganas MP",
        "email": "Steve.Georganas.MP@aph.gov.au",
        "district": "Adelaide"
      },
      {
        "name": "Mr Tony Pasin MP",
        "email": "",
        "district": "Barker"
      },
      {
        "name": "Mr Rowan Ramsey MP",
        "email": "",
        "district": "Grey"
      },
      {
        "name": "Hon Amanda Rishworth MP",
        "email": "",
        "district": "Kingston"
      },
      {
        "name": "Ms Rebekha Sharkie MP",
        "email": "Rebekha.Sharkie.MP@aph.gov.au",
        "district": "Mayo"
      },
      {
        "name": "Mr James Stevens MP",
        "email": "James.Stevens.MP@aph.gov.au",
        "district": "Sturt"
      },
      {
        "name": "Mr Tony Zappia MP",
        "email": "Tony.Zappia.MP@aph.gov.au",
        "district": "Makin"
      }
    ],
    " New South Wales": [
      {
        "name": "Hon Anthony Albanese MP",
        "email": "A.Albanese.MP@aph.gov.au",
        "district": "Grayndler MP"
      }
    ],
    "Tasmania": [
      {
        "name": "Mrs Bridget Archer MP",
        "email": "Bridget.Archer.MP@aph.gov.au",
        "district": "Bass"
      },
      {
        "name": "Hon Julie Collins MP",
        "email": "",
        "district": "Franklin"
      },
      {
        "name": "Mr Brian Mitchell MP",
        "email": "",
        "district": "Lyons"
      },
      {
        "name": "Mr Gavin Pearce MP",
        "email": "Gavin.Pearce.MP@aph.gov.au",
        "district": "Braddon"
      },
      {
        "name": "Mr Andrew Wilkie MP",
        "email": "Andrew.Wilkie.MP@aph.gov.au",
        "district": "Clark"
      }
    ],
    "Australian Capital Territory": [
      {
        "name": "Hon Dr Andrew Leigh MP",
        "email": "Andrew.Leigh.MP@aph.gov.au",
        "district": "Fenner"
      },
      {
        "name": "Ms Alicia Payne MP",
        "email": "Alicia.Payne.MP@aph.gov.au",
        "district": "Canberra"
      },
      {
        "name": "Mr David Smith MP",
        "email": "David.Smith.MP@aph.gov.au",
        "district": "Bean"
      }
    ],
    "New South Wales": [
      {
        "name": "Mr John Alexander OAM MP",
        "email": "",
        "district": "Bennelong"
      },
      {
        "name": "Hon Sharon Bird MP",
        "email": "Sharon.Bird.MP@aph.gov.au",
        "district": "Cunningham"
      },
      {
        "name": "Hon Chris Bowen MP",
        "email": "Chris.Bowen.MP@aph.gov.au",
        "district": "McMahon"
      },
      {
        "name": "Hon Tony Burke MP",
        "email": "Tony.Burke.MP@aph.gov.au",
        "district": "Watson"
      },
      {
        "name": "Hon Linda Burney MP",
        "email": "Linda.Burney.MP@aph.gov.au",
        "district": "Barton"
      },
      {
        "name": "Hon Jason Clare MP",
        "email": "",
        "district": "Blaxland"
      },
      {
        "name": "Ms Sharon Claydon MP",
        "email": "",
        "district": "Newcastle"
      },
      {
        "name": "Hon David Coleman MP",
        "email": "",
        "district": "Banks"
      },
      {
        "name": "Mr Pat Conaghan MP",
        "email": "",
        "district": "Cowper"
      },
      {
        "name": "Mr Pat Conroy MP",
        "email": "Pat.Conroy.MP@aph.gov.au",
        "district": "Shortland"
      },
      {
        "name": "Hon Mark Coulton MP",
        "email": "Mark.Coulton.MP@aph.gov.au",
        "district": "Parkes"
      },
      {
        "name": "Hon Justine Elliot MP",
        "email": "Justine.Elliot.MP@aph.gov.au",
        "district": "Richmond"
      },
      {
        "name": "Mr Jason Falinski MP",
        "email": "Jason.Falinski.MP@aph.gov.au",
        "district": "Mackellar"
      },
      {
        "name": "Hon Joel Fitzgibbon MP",
        "email": "Joel.Fitzgibbon.MP@aph.gov.au",
        "district": "Hunter"
      },
      {
        "name": "Hon Paul Fletcher MP",
        "email": "Paul.Fletcher.MP@aph.gov.au",
        "district": "Bradfield"
      },
      {
        "name": "Dr Mike Freelander MP",
        "email": "Mike.Freelander.MP@aph.gov.au",
        "district": "Macarthur"
      },
      {
        "name": "Hon Andrew Gee MP",
        "email": "",
        "district": "Calare"
      },
      {
        "name": "Hon Dr David Gillespie MP",
        "email": "",
        "district": "Lyne"
      },
      {
        "name": "Hon Alex Hawke MP",
        "email": "",
        "district": "Mitchell"
      },
      {
        "name": "Mr Chris Hayes MP",
        "email": "Chris.Hayes.MP@aph.gov.au",
        "district": "Fowler"
      },
      {
        "name": "Hon Kevin Hogan MP",
        "email": "Kevin.Hogan.MP@aph.gov.au",
        "district": "Page"
      },
      {
        "name": "Hon Ed Husic MP",
        "email": "",
        "district": "Chifley"
      },
      {
        "name": "Mr Stephen Jones MP",
        "email": "Stephen.Jones.MP@aph.gov.au",
        "district": "Whitlam"
      },
      {
        "name": "Hon Barnaby Joyce MP",
        "email": "",
        "district": "New England"
      },
      {
        "name": "Mr Craig Kelly MP",
        "email": "",
        "district": "Hughes"
      },
      {
        "name": "Mr Julian Leeser MP",
        "email": "Julian.Leeser.MP@aph.gov.au",
        "district": "Berowra"
      },
      {
        "name": "Hon Sussan Ley MP",
        "email": "",
        "district": "Farrer"
      },
      {
        "name": "Dr Fiona Martin MP",
        "email": "Fiona.Martin.MP@aph.gov.au",
        "district": "Reid"
      },
      {
        "name": "Ms Kristy McBain MP",
        "email": "Kristy.McBain.MP@aph.gov.au",
        "district": "Eden-Monaro"
      },
      {
        "name": "Ms Emma McBride MP",
        "email": "Emma.McBride.MP@aph.gov.au",
        "district": "Dobell"
      },
      {
        "name": "Hon Michael McCormack MP",
        "email": "",
        "district": "Riverina"
      },
      {
        "name": "Mrs Melissa McIntosh MP",
        "email": "",
        "district": "Lindsay"
      },
      {
        "name": "Hon Scott Morrison MP",
        "email": "",
        "district": "Cook"
      },
      {
        "name": "Ms Julie Owens MP",
        "email": "Julie.Owens.MP@aph.gov.au",
        "district": "Parramatta"
      },
      {
        "name": "Mrs Fiona Phillips MP",
        "email": "",
        "district": "Gilmore"
      },
      {
        "name": "Hon Tanya Plibersek MP",
        "email": "",
        "district": "Sydney"
      },
      {
        "name": "Ms Michelle Rowland MP",
        "email": "Michelle.Rowland.MP@aph.gov.au",
        "district": "Greenway"
      },
      {
        "name": "Mr Dave Sharma MP",
        "email": "Dave.Sharma.MP@aph.gov.au",
        "district": "Wentworth"
      },
      {
        "name": "Ms Anne Stanley MP",
        "email": "Anne.Stanley.MP@aph.gov.au",
        "district": "Werriwa"
      },
      {
        "name": "Ms Zali Steggall OAM  MP",
        "email": "Zali.Steggall.MP@aph.gov.au",
        "district": "Warringah"
      },
      {
        "name": "Ms Meryl Swanson MP",
        "email": "Meryl.Swanson.MP@aph.gov.au",
        "district": "Paterson"
      },
      {
        "name": "Hon Angus Taylor MP",
        "email": "",
        "district": "Hume"
      },
      {
        "name": "Ms Susan Templeman MP",
        "email": "susan.templeman.mp@aph.gov.au",
        "district": "Macquarie"
      },
      {
        "name": "Hon Matt Thistlethwaite MP",
        "email": "Matt.Thistlethwaite.MP@aph.gov.au",
        "district": "Kingsford Smith"
      },
      {
        "name": "Mrs Lucy Wicks MP",
        "email": "",
        "district": "Robertson"
      },
      {
        "name": "Mr Trent Zimmerman MP",
        "email": "Trent.Zimmerman.MP@aph.gov.au",
        "district": "North Sydney"
      }
    ],
    "Victoria": [
      {
        "name": "Dr Katie Allen MP",
        "email": "Katie.Allen.MP@aph.gov.au",
        "district": "Higgins"
      },
      {
        "name": "Hon Kevin Andrews MP",
        "email": "menzies@aph.gov.au",
        "district": "Menzies"
      },
      {
        "name": "Mr Adam Bandt MP",
        "email": "Adam.Bandt.MP@aph.gov.au",
        "district": "Melbourne"
      },
      {
        "name": "Mr Russell Broadbent MP",
        "email": "Russell.Broadbent.MP@aph.gov.au",
        "district": "Monash"
      },
      {
        "name": "Mr Josh Burns MP",
        "email": "Josh.Burns.MP@aph.gov.au",
        "district": "Macnamara"
      },
      {
        "name": "Hon Anthony Byrne MP",
        "email": "Anthony.Byrne.MP@aph.gov.au",
        "district": "Holt"
      },
      {
        "name": "Hon Darren Chester MP",
        "email": "",
        "district": "Gippsland"
      },
      {
        "name": "Ms Lisa Chesters MP",
        "email": "",
        "district": "Bendigo"
      },
      {
        "name": "Ms Libby Coker MP",
        "email": "Libby.Coker.MP@aph.gov.au",
        "district": "Corangamite"
      },
      {
        "name": "Hon Mark Dreyfus QC MP",
        "email": null,
        "district": "Isaacs"
      },
      {
        "name": "Hon Damian Drum MP",
        "email": "",
        "district": "Nicholls"
      },
      {
        "name": "Hon Josh Frydenberg MP",
        "email": "",
        "district": "Kooyong"
      },
      {
        "name": "Mr Andrew Giles MP",
        "email": "",
        "district": "Scullin"
      },
      {
        "name": "Dr Helen Haines MP",
        "email": "",
        "district": "Indi"
      },
      {
        "name": "Mr Julian Hill MP",
        "email": "Julian.Hill.MP@aph.gov.au",
        "district": "Bruce"
      },
      {
        "name": "Hon Greg Hunt MP",
        "email": "Greg.Hunt.MP@aph.gov.au",
        "district": "Flinders"
      },
      {
        "name": "Ms Ged Kearney MP",
        "email": "Ged.Kearney.MP@aph.gov.au",
        "district": "Cooper"
      },
      {
        "name": "Mr Peter Khalil MP",
        "email": "Peter.Khalil.MP@aph.gov.au",
        "district": "Wills"
      },
      {
        "name": "Hon Catherine King MP",
        "email": "Catherine.King.MP@aph.gov.au",
        "district": "Ballarat"
      },
      {
        "name": "Ms Gladys Liu MP",
        "email": "",
        "district": "Chisholm"
      },
      {
        "name": "Hon Richard Marles MP",
        "email": "",
        "district": "Corio"
      },
      {
        "name": "Mr Rob Mitchell MP",
        "email": "",
        "district": "McEwen"
      },
      {
        "name": "Dr Daniel Mulino MP",
        "email": "Daniel.Mulino.MP@aph.gov.au",
        "district": "Fraser"
      },
      {
        "name": "Ms Peta Murphy MP",
        "email": "",
        "district": "Dunkley"
      },
      {
        "name": "Hon Brendan O'Connor MP",
        "email": "Brendan.OConnor.MP@aph.gov.au",
        "district": "Gorton"
      },
      {
        "name": "Ms Clare O'Neil MP",
        "email": "Clare.ONeil.MP@aph.gov.au",
        "district": "Hotham"
      },
      {
        "name": "Ms Joanne Ryan MP",
        "email": "",
        "district": "Lalor"
      },
      {
        "name": "Hon Bill Shorten MP",
        "email": "Bill.Shorten.MP@aph.gov.au",
        "district": "Maribyrnong"
      },
      {
        "name": "Hon Tony Smith MP",
        "email": "Tony.Smith.MP@aph.gov.au",
        "district": "Casey"
      },
      {
        "name": "Hon Michael Sukkar MP",
        "email": "",
        "district": "Deakin"
      },
      {
        "name": "Hon Dan Tehan MP",
        "email": "",
        "district": "Wannon"
      },
      {
        "name": "Ms Kate Thwaites MP",
        "email": "Kate.Thwaites.MP@aph.gov.au",
        "district": "Jagajaga"
      },
      {
        "name": "Hon Alan Tudge MP",
        "email": "",
        "district": "Aston"
      },
      {
        "name": "Ms Maria Vamvakinou MP",
        "email": "Maria.Vamvakinou.MP@aph.gov.au",
        "district": "Calwell"
      },
      {
        "name": "Mr Tim Watts MP",
        "email": "",
        "district": "Gellibrand"
      },
      {
        "name": "Dr Anne Webster MP",
        "email": "Anne.Webster.MP@aph.gov.au",
        "district": "Mallee"
      },
      {
        "name": "Mr Tim Wilson MP",
        "email": "Tim.Wilson.MP@aph.gov.au",
        "district": "Goldstein"
      },
      {
        "name": "Hon Jason Wood MP",
        "email": "",
        "district": "La Trobe"
      }
    ],
    "Queensland": [
      {
        "name": "Hon Karen Andrews MP",
        "email": "Karen.Andrews.MP@aph.gov.au",
        "district": "McPherson"
      },
      {
        "name": "Ms Angie Bell MP",
        "email": "Angie.Bell.MP@aph.gov.au",
        "district": "Moncrieff"
      },
      {
        "name": "Hon Scott Buchholz MP",
        "email": "",
        "district": "Wright"
      },
      {
        "name": "Ms Terri Butler MP",
        "email": "",
        "district": "Griffith"
      },
      {
        "name": "Dr Jim Chalmers MP",
        "email": "",
        "district": "Rankin"
      },
      {
        "name": "Mr George Christensen MP",
        "email": "",
        "district": "Dawson"
      },
      {
        "name": "Mr Milton Dick MP",
        "email": "",
        "district": "Oxley"
      },
      {
        "name": "Hon Peter Dutton MP",
        "email": "Peter.Dutton.MP@aph.gov.au",
        "district": "Dickson"
      },
      {
        "name": "Hon Warren Entsch MP",
        "email": "",
        "district": "Leichhardt"
      },
      {
        "name": "Hon Trevor Evans MP",
        "email": "Trevor.Evans.MP@aph.gov.au",
        "district": "Brisbane"
      },
      {
        "name": "Mr Garth Hamilton MP",
        "email": "Garth.Hamilton.MP@aph.gov.au",
        "district": "Groom"
      },
      {
        "name": "Hon Luke Howarth MP",
        "email": "",
        "district": "Petrie"
      },
      {
        "name": "Hon Bob Katter MP",
        "email": "Bob.Katter.MP@aph.gov.au",
        "district": "Kennedy"
      },
      {
        "name": "Mr Andrew Laming MP",
        "email": "",
        "district": "Bowman"
      },
      {
        "name": "Hon Michelle Landry MP",
        "email": "",
        "district": "Capricornia"
      },
      {
        "name": "Hon David Littleproud MP",
        "email": "David.Littleproud.MP@aph.gov.au",
        "district": "Maranoa"
      },
      {
        "name": "Hon Shayne Neumann MP",
        "email": "Shayne.Neumann.MP@aph.gov.au",
        "district": "Blair"
      },
      {
        "name": "Mr Ted O'Brien MP",
        "email": "ted.obrien.mp@aph.gov.au",
        "district": "Fairfax"
      },
      {
        "name": "Mr Llew O'Brien MP",
        "email": "",
        "district": "Wide Bay"
      },
      {
        "name": "Mr Ken O'Dowd MP",
        "email": "",
        "district": "Flynn"
      },
      {
        "name": "Mr Graham Perrett MP",
        "email": "",
        "district": "Moreton"
      },
      {
        "name": "Hon Keith Pitt MP",
        "email": "",
        "district": "Hinkler"
      },
      {
        "name": "Hon Stuart Robert MP",
        "email": "",
        "district": "Fadden"
      },
      {
        "name": "Mr Julian Simmonds MP",
        "email": "",
        "district": "Ryan"
      },
      {
        "name": "Mr Phillip Thompson OAM MP",
        "email": "Phillip.Thompson.MP@aph.gov.au",
        "district": "Herbert"
      },
      {
        "name": "Mr Bert van Manen MP",
        "email": "",
        "district": "Forde"
      },
      {
        "name": "Mr Ross Vasta MP",
        "email": "",
        "district": "Bonner"
      },
      {
        "name": "Mr Andrew Wallace MP",
        "email": "Andrew.Wallace.MP@aph.gov.au",
        "district": "Fisher"
      },
      {
        "name": "Ms Anika Wells MP",
        "email": "Anika.Wells.MP@aph.gov.au",
        "district": "Lilley"
      },
      {
        "name": "Mr Terry Young MP",
        "email": "Terry.Young.MP@aph.gov.au",
        "district": "Longman"
      }
    ]
  }
}


    
]