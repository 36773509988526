import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import educationImage from '../../assets/images/education.jpg'
import linkImage from '../../assets/images/link.jpg'

const LinkURL = ({ linkURL }) => {
  if (Object.keys(linkURL).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <>
      <div className='student'>
        <div className='card'>
          {linkURL.imageUrl.length > 1 ? (
            <img
              src={linkURL.imageUrl}
              style={imageStyles}
              className='blog-card-image'
              alt={linkURL.title}
            />
          ) : (
            <img
              src={linkImage}
              style={imageStyles}
              className='blog-card-image'
              alt={linkURL.title}
            />
          )}
          <div>
            <h2 className='card-title'>{linkURL.title}</h2>
            <small className='card-subtitle'>{linkURL.subtitle} </small>
            <small className='card-date'>
              Posted on <i className='far fa-clock'></i>{' '}
              {moment(linkURL.createdAt).format('ll')}{' '}
            </small>
            <div>
              {linkURL.description && (
                <ReactMarkdown source={linkURL.description.slice(0, 250)} />
              )}
               <small className='read-more'>Read More</small>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

LinkURL.propTypes = {}

export default LinkURL
