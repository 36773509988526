import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import Spinner from '../Spinner'
import Layout from '../Layout'
import Video from './Video'

const Videos = (props) => {
  let renderContent
  if (props.videos.length) {
    renderContent = props.videos.map((video, i) => (
      <div key={video._id} className='students'>
        <NavLink to={`/videos/${video._id}`}>
          <Video video={video} />
        </NavLink>
      </div>
    ))
  } else if (props.videos.length === 0) {
    renderContent = <div>No blogs found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Videos: ' + props.videos.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}

Videos.propTypes = {}

export default Videos
