import React, {useState, createRef, useRef} from 'react'
import { NavLink } from 'react-router-dom'
import Massacre from './Massacre'
import MassacreRow from './MassacreRow'
import Spinner from '../Spinner'
import Layout from '../Layout'
import ReactToPdf from 'react-to-pdf'
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';

const ref = createRef()
const MassacreTable = (props) => {
  return (
    <div style= {{overflowX:'auto'}}>
    <div style = {{margin:'15px 0'}}>
    <p>
    <span style = {{color:'red', fontWeight:'bold'}}>Disclaimer:</span> The contents of the fact sheet are collected from human rights institutions and mainstream and social media reports concerning sustained human rights abuses, ethnic cleansing, and genocide throughout Ethiopia against the people of Amhara. Editors exert their utmost effort and due diligence to discriminate unsound and unwarranted information. Yet, it is the responsibility of the consumers to further ascertain the information contained herein.
    </p>
    
    </div>
    <table 
      className='massacre-table'>
     <thead>
     <tr>
      <th>Case No.</th>
      <th>Description</th>
      <th>Location</th>
      <th>Date</th>
      <th>Evidences</th>
      <th>Casualties</th>
      </tr>
     </thead>
     <tbody>
     {props.renderContent}
     </tbody>
    </table>
    </div>
   
  )
}


const options = {

}


const tableStyles = {
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center'
}

const CardView = ({massacre}) => {
  return (
    <div key={massacre._id} className='students'>
        <NavLink to={`/massacres/${massacre._id}`}>
          <Massacre massacre={massacre} />
        </NavLink>
      </div>
  )
}
const Massacres = (props) => {
 const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [viewType, setTable] = useState('card')
  const changeCardView = () => {
    setTable('card')
  }
  const changeTableView = () => {
    setTable('table')
  }
  let renderContent
  if (props.data.length) {
    renderContent = props.data.map((massacre, i) => (
      viewType == 'table' ? <MassacreRow key = {massacre._id} massacre = {massacre} />: <CardView key = {massacre._id} massacre = {massacre} />
    ))
  } else if (props.data.length === 0) {
    renderContent = <div>No blogs found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={`Massacres (${props.data.length})`}>
      <div className='main-wrapper'>
      <i className="fas fa-table" onClick = {changeTableView} title='Table View'></i>
      <i className="fas fa-th" onClick = {changeCardView} title='Card View'></i>
        <div className='students'>
        {
          viewType == 'card' ? renderContent : (
            <MassacreTable renderContent = {renderContent} ref={componentRef}/>

          )
        }

{
  /* 
    {
          viewType == 'table' ?  (<ReactToPdf targetRef={ref} filename="div-blue.pdf" options={options}>
        {({toPdf}) => (
            <button onClick={toPdf}>Generate pdf</button>
        )}
    </ReactToPdf>): ''
        }

       {
          viewType == 'table' ? ( <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      />): ''
       }
  
  
  */
}
        </div>

      </div>
    </Layout>
  )
}

export default Massacres

