import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import ProgressBar from './ProgressBar'
import FileInputField from '../shared/FileInputField'

const AddVideo = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    location: '',
    description: '',
    happenedOn: '',
    createdAt: '',
    image: '',
  }
  const [errors, setErrors] = useState({})
  const [percentage, setPercentage] = useState(0)
  const [upLoadedPercentage, setUpLoadedPercentage] = useState(0)
  const [formData, setFormData] = useState(initialState)
  const [completed, setCompleted] = useState(0)
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type === 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }
  // useEffect(() => {
  //   setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1), 2000)
  // })
  const onSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    const url = '/api/v1.0.0/videos'
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        let percent = Math.floor((loaded * 100) / total)
        setPercentage(percent)
        console.log(`${loaded}kb of ${total}kb | ${percent}`)
      },
    }
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }

    axios
      .post(url, data, options)
      .then((response) => {
        if (percentage === 100) {
           props.fetchData()
      //  window.location.href = '/videos'
        }
      })
      .catch((error) => {
        setErrors(error.response.data)
      })
         setTimeout(() => {
      // window.location.href = '/videos'
   }, 3000)
   window.confirm('Uploading the video, check the video in the videos sections after some minutes')
     

    // try {
    //   await axios(
    //     {
    //       method: 'post',
    //       url: url,
    //       data,
    //     },
    //     options
    //   )
    // } catch (error) {
    //   setErrors(error.response.data)
    // }
    // props.fetchData()
  }

  const {
    title,
    subtitle,
    location,
    happenedOn,
    createdAt,
    description,
  } = errors

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className='row'>
        <TextInputField
          label='Title'
          id='title'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          error={title}
        />

        <TextInputField
          label='Subtitle'
          id='subtitle'
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='News Subtitle'
          error={subtitle}
        />

        <TextInputField
          label='Location'
          id='location'
          type='text'
          name='location'
          value={formData.location}
          onChange={onChange}
          placeholder='Region, zone, woreda, kebele'
          error={location}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='Happened on'
          type='datetime-local'
          id='happenedOn'
          name='happenedOn'
          value={formData.happenedOn}
          onChange={onChange}
          error={happenedOn}
        />
        <TextInputField
          label='Published on'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={formData.createdAt}
          onChange={onChange}
          error={createdAt}
        />
        <div></div>
      </div>

      <TextAreaField
        label='Description'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Your post  goes here ...'
        error={description}
      />

      {/* <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          accept='file_extension|audio/*|video/*|image/*|media_type'
          size='600'
        />
        {formData.image && (
          <ProgressBar bgcolor={'#6a1b9a'} completed={percentage} />
        )}
      </div> */}
      <FileInputField
        onChange={onChange}
        formData={formData}
        // percentage={percentage}
        text='Upload Video'
      />

      <button className='btn' type='submit' id='submit-button'>
        {props.title}
      </button>
    </form>
  )
}

// AddStudent.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default AddVideo
