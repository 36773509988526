import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import FileInputField from '../shared/FileInputField'

const AddVictim = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    fullName: '',
    location: '',
    victimizer: '',
    killingType: '',
    happenedOn: '',
    description: '',
    createdAt: '',
    image: '',
  }

  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(initialState)
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const url = '/api/v1.0.0/victims'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }

    try {
      await axios({
        method: 'post',
        url: url,
        data,
      })
    } catch (error) {
      setErrors(error.response.data)
    }

    props.fetchData()
    window.location.href = '/victims'
  }

  const {
    title,
    subtitle,
    fullName,
    location,
    victimizer,
    killingType,
    happenedOn,
    description,
    createdAt,
    image,
  } = errors

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className='row'>
        <TextInputField
          label='Title'
          id='tile'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          error={title}
        />
        <TextInputField
          label='Subtitle'
          id='subtitle'
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='Subtitle'
          error={subtitle}
        />
        <TextInputField
          label='Full Name'
          id='fullName'
          name='fullName'
          cols='120'
          rows='2'
          value={formData.fullName}
          onChange={onChange}
          placeholder='Full name of the victim...'
          error={fullName}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='Location'
          id='location'
          type='text'
          name='location'
          value={formData.location}
          onChange={onChange}
          placeholder='Region, zone, wordea, kebele ..'
          error={location}
        />
        <TextInputField
          label='Victimizer'
          id='victimizer'
          type='text'
          name='victimizer'
          value={formData.victimizer}
          onChange={onChange}
          placeholder='ገዳይ/ገዳዮች'
          error={victimizer}
        />
        <TextInputField
          label='Killing Type'
          id='killingType'
          type='text'
          name='killingType'
          value={formData.killingType}
          onChange={onChange}
          placeholder='የአገዳደል ሁኔታ'
          error={killingType}
        />
      </div>

      <div className='row'>
        <TextInputField
          label='Killing Date and time'
          type='datetime-local'
          id='happenedOn'
          name='happenedOn'
          value={happenedOn}
          onChange={onChange}
          error={happenedOn}
        />
        <TextInputField
          label='Publish'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={createdAt}
          onChange={onChange}
          error={createdAt}
        />
        <div></div>
      </div>

      <TextAreaField
        label='Description'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Your post  goes here ...'
        error={description}
      />

      {/* <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          size='600'
        />
      </div> */}
      <FileInputField
        onChange={onChange}
        formData={formData}
        // percentage={percentage}
        text='Upload Image'
      />

      <button className='btn' type='submit' id='submit-button'>
        {props.title}
      </button>
    </form>
  )
}

export default AddVictim
