import React from 'react'
import PropTypes from 'prop-types'
import { isAdmin, onlyAdmin } from './ShowActions'

const ApproveButton = ({ onClick, auth, item }) => {
  let result = onlyAdmin(auth) && item.approved === false
  return result ? (
    <button className='btn' onClick={onClick}>
      Approve
    </button>
  ) : (
    ''
  )
}

ApproveButton.propTypes = {}

export default ApproveButton
