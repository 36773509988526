import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import educationImage from '../../assets/images/education.jpg'
import { connect } from 'react-redux'
import SocialShareButton from '../shared/SocialShareButton'
import ShowActions from '../shared/ShowActions'
const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({ linkURL, auth, deleteLinkURL, id }) => (
  <div className='single-student'>
    <div className='profile-header'>
      <div className='profile-category' style={{ textAlign: 'center' }}>
        <h4 className='card-title'>{linkURL.title}</h4>
        <small className='card-subtitle'>{linkURL.subtitle}</small>
        <br />
        <small className='card-date'>
          Published on <i className='far fa-clock'></i>{' '}
          {moment(linkURL.createdAt).format('ll')}
        </small>
      </div>
    </div>
    <div className='profile-body'>
      <div className='profile-category'>
        <div className='description'>
          {<ReactMarkdown source={linkURL.description} />}
        </div>
        <div className='description-image'>
          {linkURL.imageUrl.length > 1 && (
            <img
              src={linkURL.imageUrl}
              style={imageStyles}
              className='blog-image'
              alt={linkURL.title}
            />
          )}
        </div>
      </div>
    </div>
    <div className='profile-footer'>
      <div>
        <small className='card-date'>
          <i className='far fa-clock'></i> Published on{' '}
          {moment(linkURL.createdAt).format('ll')}{' '}
        </small>
      </div>

      <div>
        <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
        <SocialShareButton category={'links'} id={linkURL._id} />
      </div>
      <ShowActions
        auth={auth}
        item={linkURL}
        deleteItem={deleteLinkURL}
        name='links'
      />

      {/* {isAuthenticated && (
        <div className='icons'>
          <NavLink to={`/links/edit/${linkURL._id}`} className='btn-primary'>
            {' '}
            <i className='far fa-edit'></i>
          </NavLink>

          <i
            className='btn-danger far fa-trash-alt'
            onClick={deleteLinkURL}
          ></i>
        </div>
      )} */}
    </div>
  </div>
)

const LinkURLDetail = (props) => {
  const { auth } = props
  const deleteLinkURL = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/links/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/links')
  }

  let renderContent
  let item

  if (props.data.length > 0) {
    const linkURL = props.data.find((st) => st._id == props.match.params.id)
    item = linkURL
    renderContent = (
      <Details linkURL={linkURL} auth={auth} deleteLinkURL={deleteLinkURL} />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={item.title} category='links' id={item && item._id}>
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

LinkURLDetail.propTypes = {}
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(LinkURLDetail)
