import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import Status from '../shared/Status'
import BlogStat from '../shared/BlogStat'

const Video = ({ video }) => {
  if (Object.keys(video).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <>
      <div className='student'>
        <div className='card'>
          {video.videoUrl.length > 1 ? (
            <video
              src={video.videoUrl}
              controls
              style={imageStyles}
              className='blog-card-image'
              alt={video.title}
            ></video>
          ) : (
            <i className='fas fa-user'></i>
          )}
          <div>
            {/* <h2 className='card-title'>{video.title}</h2>
            <small className='card-subtitle'>{video.subtitle} </small>
            <small className='card-date'>
              Published on <i className='far fa-clock'></i>{' '}
              {moment(video.createdAt).format('ll')}{' '}
            </small>
            <Status approved={video.approved} /> */}
            <BlogStat item={video} />
            <div>
              <p>{video.description && video.description.slice(0, 250)} ...</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Video.propTypes = {}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps, null)(Video)
