import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Title from './Title'
import SEO from './SEO'

const Layout = ({ children, title, imageUrl }) => {
  return (
    <>
      <SEO title={title} imageUrl={imageUrl} />
      <Header title={title} />
      <main>
        <div className='main-wrapper'>
          {title && title.split(' ').length < 5 && <Title title={title} />}
          {children}
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Layout
