import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import axios from 'axios'
import moment from 'moment'
import Spinner from './shared/Spinner'

const Reports = () => {
  const [reports, setReports] = useState([])

  useEffect(() => {
    fetchData()
  })

  const fetchData = async () => {
    const url = '/api/v1.0.0/reports'
    const response = await axios(url)
    const data = response.data
    setReports(data)
  }
  const renderReports = () => {
    if (reports.length > 0) {
      return (
        <div className='reports'>
          {reports.map(({ message, title, createdAt }) => (
            <div className='report'>
              <h1>{title}</h1>
              <p>{message}</p>
              <small className='date-card'>
                Reported on {moment(createdAt).format('ll hh:mm A')}{' '}
              </small>
            </div>
          ))}
        </div>
      )
    } else if (reports.length === 0) {
      return (
        <div className='reports'>
          <div className='report'>No report found</div>
        </div>
      )
    } else {
      return <Spinner />
    }
  }
  return <Layout>{renderReports()}</Layout>
}

export default Reports
