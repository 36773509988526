import React, { Component } from 'react'
import axios from 'axios'
import TextInputField from '../shared/TextInputField'

import Layout from '../Layout'
class ResetPassword extends Component {
  state = {
    email: '',
    password: '',
    password2: '',
    errors: {}
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleSubmit = e => {
    e.preventDefault()
    const { email, password, password2 } = this.state

    this.setState({ errors: {} })
    axios
      .post(`/api/users/resetpassword/${this.props.match.params.id}`, {
        email,
        password,
        password2
      })
      .then(response => {
        
        this.props.history.push('/signin')
      })
      .catch(err => this.setState({ errors: err.response.data }))
  }
  render() {
    const { errors } = this.state

    return (
      <Layout>
        <div className='auth-outer'>
          <div className='signin-form-wrapper'>
            <div>
              <h3 className='auth-title'>Reset password</h3>
            </div>
            <form onSubmit={this.handleSubmit}>
              <TextInputField
                name='email'
                type='email'
                placeholder='Email'
                value={this.state.email}
                onChange={this.handleChange}
                error={errors.email}
              />
              <TextInputField
                name='password'
                type='password'
                placeholder='Password'
                value={this.state.password}
                onChange={this.handleChange}
                error={errors.password}
              />
              <TextInputField
                name='password2'
                type='password'
                placeholder='Confirm Password'
                value={this.state.password2}
                onChange={this.handleChange}
                error={errors.password2}
              />

              <button type='submit' className='btn  btn-auth'>
                Reset
              </button>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

ResetPassword.propTypes = {}

export default ResetPassword
