import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import FileInputField from '../shared/FileInputField'
import styles from '../shared/input-file.module.scss'

const AddEconomy = (props) => {
  const initialState = {
    caseNum:'',
    title: '',
    subtitle: '',
    quantity: '',
    location: '',
    happenedOn: '',
    description: '',
    createdAt: '',
    image: '',
    description: '',
    shortDescription:'',
    evidence:'',
    remark:'',
    createdAt: '',
    image: '',
    files:[]
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(initialState)
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      setFormData({ ...formData,files:[...e.target.files]})
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    const url = '/api/v1.0.0/massacres'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }

    const data = new FormData()
    for (const key in formData) {
      if (key ==='files'){
         formData[key].forEach((file) => data.append(`files[]`, file));
      }
      data.append(key, formData[key])
    }
    try {
      await axios({
        method: 'post',
        url: url,
        data,
      })
    } catch (error) {
      setErrors(error.response.data)
    }
    props.fetchData()
    window.location.href = '/massacres'
  }

  const {
    caseNum,
    title,
    subtitle,
    quantity,
    location,
    victimizer,
    killingType,
    happenedOn,
    shortDescription,
    evidence,
    remark,
    description,
    createdAt,
  } = errors

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className='row'>
       
        <TextInputField
          label='Title'
          id='title'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          error={title}
        />

        <TextInputField
          label='Subtitle'
          id='subtitle'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='News Subtitle'
          error={subtitle}
        />
        <TextInputField
          label='location'
          id='location'
          name='location'
          value={formData.location}
          onChange={onChange}
          placeholder='Region, zone, woreda, kebele ...'
          error={location}
        />
      </div>

      <div className='row'>
        <TextInputField
          label='Number of people killed'
          id='quantity'
          name='quantity'
          value={formData.quantity}
          onChange={onChange}
          placeholder='የተገደለ ሰው ብዛት'
          error={quantity}
        />
        <TextInputField
          label='Victimizer'
          id='victimizer'
          name='victimizer'
          value={formData.victimizer}
          onChange={onChange}
          placeholder='ገዳይ/ገዳዮች'
          error={victimizer}
        />
        <TextInputField
          label='Killing Type'
          id='killingType'
          name='killingType'
          value={formData.killingType}
          onChange={onChange}
          placeholder='የአገዳደል ሁኔታ'
          error={killingType}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='Killing Date and time'
          type='datetime-local'
          id='happenedOn'
          name='happenedOn'
          value={formData.happenedOn}
          onChange={onChange}
          error={happenedOn}
        />
        <TextInputField
          label='Publish'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={formData.createdAt}
          onChange={onChange}
          error={createdAt}
        />
        <div></div>
      </div>
      <div className='row'>
      <TextInputField
          label='Case Number'
          id='caseNum'
          name='caseNum'
          value={formData.caseNum}
          onChange={onChange}
          placeholder='Case Number'
          error={caseNum}
        />
      </div>
        <TextAreaField
        label='Short Description'
        id='shortDescription'
        name='shortDescription'
        cols='120'
        rows='1'
        value={formData.shortDescription}
        onChange={onChange}
        placeholder='Description ...'
        error={shortDescription}
      />
      <TextAreaField
        label='Evidence'
        id='evidence'
        name='evidence'
        cols='120'
        rows='1'
        value={formData.evidence}
        onChange={onChange}
        placeholder='Evidence links separated by comma and one space'
        error={evidence}
      />
      <TextAreaField
        label='Remarks'
        id='remark'
        name='remark'
        cols='120'
        rows='1'
        value={formData.remark}
        onChange={onChange}
        placeholder='remark ...'
        error={remark}
      />
       <TextAreaField
        label='Details'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Details...'
        error={description}
      />

    
<div className='form-group file-upload'>
        <input
          type='file'
          name='files'
          id='files'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          multiple
          size='600'
          // className={styles.inputfile}
        />
         {/* <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload Image
          </label> */}
      </div>  
{/* 
   
      <FileInputField
        onChange={onChange}
        formData={formData}
        // percentage={percentage}
        text='Upload Image'
      />
      */}
    

      <button className='btn' type='submit' id='submit-button'>
        {props.title}
      </button>
    </form>
  )
}

// AddStudent.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default AddEconomy
