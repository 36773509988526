import React, { Component } from 'react'
import { Link} from 'react-router-dom'
import axios from 'axios'
import TextInputField from '../shared/TextInputField'
import Layout from '../Layout'
class ForgotPassword extends Component {
  state = {
    email: '',
    errors: {}
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard')
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleSubmit = e => {
    e.preventDefault()
    const { email } = this.state

    axios
      .post(`/api/users/forgotpassword`, {
        email
      })
      .then(response => {

        this.props.history.push('/check-email')
      })
      .catch(err => this.setState({ errors: err.response.data }))
  }
  render() {
    const { errors } = this.state

    return (
      <Layout title="Forgot Password">
        <div className='auth-outer'>
          <div className='signin-form-wrapper'>
            <div>
              <h1 className='auth-title'>Forgot your password</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <TextInputField
                name='email'
                type='email'
                placeholder='Enter you email'
                value={this.state.email}
                onChange={this.handleChange}
                error={errors.email}
              />

              <button type='submit' className='btn  btn-auth'>
                Send Reset Link
              </button>

              <div>
                <small>
                  Do you remember your password ? Try it again
                  <Link className='link' to='/signin'>
                    SIGN IN
                  </Link>
                </small>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

ForgotPassword.propTypes = {}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default ForgotPassword
