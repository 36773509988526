import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Header from '../Header'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import SelectListGroup from '../shared/SelectListGroup'
import { countries } from '../../utils/countries'
import { showDateTime } from '../../utils/show_day_time'
import moment from 'moment'
import styles from '../shared/input-file.module.scss'

const EditVictim = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    fullName: '',
    location: '',
    happenedOn: '',
    description: '',
    createdAt: '',
    image: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const url = `/api/v1.0.0/victims`
    axios
      .get(url)
      .then((response) => {
        const id = props.match.params.id
        const victim = response.data.find((st) => st._id == id)
        console.log('happenedOn', victim.happenedOn, victim.createdAt)
        const {
          title,
          subtitle,
          fullName,
          location,
          victimizer,
          killingType,
          description,
          happenedOn,
          createdAt,
        } = victim

        const data = {
          title,
          subtitle,
          fullName,
          location,
          victimizer,
          killingType,
          description,
          happenedOn,
          createdAt,
        }
        setFormData(data)
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/victims/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }

    try {
      await axios({
        method: 'put',
        url: url,
        data,
      })
    } catch (error) {
      setErrors(error.response.data)
    }
    props.fetchData()
    window.location.href = `/victims/${id}`
  }

  // const onSubmit = async (e) => {
  //   e.preventDefault()
  //   const id = props.match.params.id
  //   const url = `/api/v1.0.0/histories/${id}/edit`
  //   const config = {
  //     Accept: 'application/json',
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //   }
  //   const data = new FormData()
  //   for (const key in formData) {
  //     data.append(key, formData[key])
  //   }

  //   try {
  //     await axios({
  //       method: 'post',
  //       url: url,
  //       data,
  //     })
  //   } catch (error) {
  //     setErrors(error.response.data)
  //   }
  //   props.fetchData()
  //   window.location.href = '/politics'
  // }

  const date = moment(formData.createdAt).format('YYYY-MM-DDTHH:mm:ss')
  const {
    title,
    subtitle,
    fullName,
    location,
    victimizer,
    killingType,
    description,
    happenedOn,
    createdAt,
    image,
  } = errors

  const createdAtFormatted = moment(formData.createdAt).format(
    'YYYY-MM-DDTHH:mm:ss'
  )
  const happenedOnFormatted = moment(formData.happenedOn).format(
    'YYYY-MM-DDTHH:mm:ss'
  )

  return (
    <Layout title='Edit'>
      <form onSubmit={onSubmit} noValidate>
        <div className='row'>
          <TextInputField
            label='Title'
            id='tile'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />
          <TextInputField
            label='Subtitle'
            id='subtitle'
            type='text'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='Subtitle'
            error={subtitle}
          />
          <TextInputField
            label='Full Name'
            id='fullName'
            name='fullName'
            cols='120'
            rows='2'
            value={formData.fullName}
            onChange={onChange}
            placeholder='Full name of the victim...'
            error={fullName}
          />
        </div>
        <div className='row'>
          <TextInputField
            label='Location'
            id='location'
            type='text'
            name='location'
            value={formData.location}
            onChange={onChange}
            placeholder='Region, zone, woreda, kebele ..'
            error={location}
          />
          <TextInputField
            label='Victimizer'
            id='victimizer'
            type='text'
            name='victimizer'
            value={formData.victimizer}
            onChange={onChange}
            placeholder='ገዳይ/ገዳዮች'
            error={victimizer}
          />
          <TextInputField
            label='Killing Type'
            id='killingType'
            type='text'
            name='killingType'
            value={formData.killingType}
            onChange={onChange}
            placeholder='የአገዳደል ሁኔታ'
            error={killingType}
          />
        </div>

        <div className='row'>
          <TextInputField
            label='Killing Date and time'
            type='datetime-local'
            id='happenedOn'
            name='happenedOn'
            value={happenedOnFormatted}
            onChange={onChange}
            error={happenedOn}
          />
          <TextInputField
            label='Publish'
            type='datetime-local'
            id='createdAt'
            name='createdAt'
            value={createdAtFormatted}
            onChange={onChange}
            error={createdAt}
          />
          <div></div>
        </div>

        <TextAreaField
          label='Description'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Your post  goes here ...'
          error={description}
        />

        <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
            className={styles.inputfile}
          />
          <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload Image
          </label>
        </div>

        <button className='btn' type='submit' id='submit-button'>
          Update
        </button>
      </form>
    </Layout>
  )
}

EditVictim.propTypes = {}

export default EditVictim
