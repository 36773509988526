import React, { useState } from 'react'
import AddVictim from './AddVictim'
import AddBlog from './AddBlog'
import AddImage from './AddImage'
import AddDisplacement from './AddDisplacement'
import SelectListGroup from '../shared/SelectListGroup'
import Navbar from './Navbar-backup'
import Footer from '../Footer'

import AddVideo from './AddVideo'
import AddMassacre from './AddMassacre'
import AddPolitics from './AddLinkURL'
import AddOrphan from './AddOrphan'
import AddMissing from './AddMissing'
import AddLinkURL from './AddLinkURL'
import AddEvent from './AddEvent'

// Select options for status
const options = [
  { label: '* Select Category', value: '' },
  { label: 'Displacement', value: 'Displacement' },
  { label: 'Orphan', value: 'Orphan' },
  { label: 'Massacre', value: 'Massacre' },
  { label: 'Video', value: 'Video' },
  { label: 'Victim', value: 'Victim' },
  { label: 'Missing', value: 'Missing' },
  { label: 'Link URL', value: 'Link URL' },
  { label: 'Event', value: 'Event' },
  { label: 'Other', value: 'Other' },
]

const Admin = (props) => {
  const initialState = {
    category: 'Education',
    error: '',
  }
  const [formData, setFormData] = useState(initialState)
  const onChange = (e) => {
    setFormData({ ...formData, category: e.target.value })
  }
  const { error } = formData
  let renderForm
  if (formData.category === 'Victim') {
    renderForm = <AddVictim title='Add Victim' fetchData={props.fetchVictims} />
  }
  // else if (formData.category === 'Education') {
  //   renderForm = <AddBlog title='Add ' fetchData={props.fetchBlogs} />
  // }
  else if (formData.category === 'Displacement') {
    renderForm = (
      <AddDisplacement title='Add ' fetchData={props.fetchDisplacements} />
    )
  }

  // else if (formData.category === 'Image') {
  //   renderForm = <AddImage title='Add image' fetchData={props.fetchImages} />
  // }
  else if (formData.category === 'Event') {
    renderForm = <AddEvent title='Add Event' fetchData={props.fetchEvents} />
  }
  else if (formData.category === 'Video') {
    renderForm = <AddVideo title='Add Video' fetchData={props.fetchVideos} />
  } else if (formData.category === 'Massacre') {
    renderForm = <AddMassacre title='Add' fetchData={props.fetchMassacres} />
  } else if (formData.category === 'Orphan') {
    renderForm = <AddOrphan title='Add' fetchData={props.fetchOrphans} />
  } else if (formData.category === 'Missing') {
    renderForm = <AddMissing title='Add' fetchData={props.fetchMissings} />
  } else if (formData.category === 'Link URL') {
    renderForm = <AddLinkURL title='Add' fetchData={props.fetchLinkURLs} />
  } else {
  }
  return (
    <>
      <header>
        <div className='header-wrapper'>
          <Navbar />
          <h3 className='page-title' style={{ textAlign: 'center', margin: '0 auto', color:'white'}}>Your Dashboard</h3>
          <div style={{ textAlign: 'center', margin: '0 auto', color:'white'}}>
            {' '}
            <small>You can add victims, massacres and displacements</small>
          </div>
        </div>
      </header>
      <main>
        <div className='main-wrapper'>
          <SelectListGroup
            id='category'
            name='category'
            value={formData.category}
            onChange={onChange}
            options={options}
            error={error}
          />
          {renderForm}
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Admin
