import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Victim from './Victim'

import Spinner from '../Spinner'
import Layout from '../Layout'

const Victims = (props) => {
  let renderContent
  if (props.data.length) {
    renderContent = props.data.map((victim, i) => (
      <div key={victim._id} className='students'>
        <NavLink to={`/victims/${victim._id}`}>
          <Victim victim={victim} />
        </NavLink>
      </div>
    ))
  } else if (props.data.length === 0) {
    renderContent = <div>No blogs found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={`Victims (${props.data.length})`}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}

export default Victims

