import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import moment from 'moment'
import styles from '../shared/input-file.module.scss'

const EditHistory = (props) => {
  const initialState = {
    caseNum:'',
    title: '',
    subtitle: '',
    quantity: '',
    location: '',
    happenedOn: '',
    description: '',
    shortDescription:'',
    evidence:'',
    remark:'',
    killingType: '',
    victimizer: '',
    createdAt: '',
    image: '',
    createdAt: '',
    files:[],
  }
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const url = `/api/v1.0.0/massacres`
    axios
      .get(url)
      .then((response) => {
        const id = props.match.params.id
        const massacre = response.data.find((st) => st._id == id)

        const {
          caseNum,
          title,
          subtitle,
          quantity,
          location,
          victimizer,
          killingType,
          happenedOn,
          createdAt,
          description,
          shortDescription,
          evidence,
          remark
        } = massacre

        const data = {
          caseNum,
          title,
          subtitle,
          quantity,
          location,
          victimizer,
          killingType,
          happenedOn,
          createdAt,
          description,
          shortDescription,
          evidence,
          remark
        }
        setFormData(data)
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value})
  
    if (type == 'file') {
      setFormData({ ...formData,files:[...e.target.files]})
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/massacres/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
   const data = new FormData()
    for (const key in formData) {
      if (key ==='files'){
         formData[key].forEach((file) => data.append(`files[]`, file))
      }
      data.append(key, formData[key])
    }

    axios({
      method: 'put',
      url: url,
      data
    }).then((response) => {})
    props.fetchData()
    props.history.push(`/massacres/${id}`)
  }

  const createdAtFormatted = moment(formData.createdAt).format(
    'YYYY-MM-DDTHH:mm:ss'
  )
  const happenedOnFormatted = moment(formData.happenedOn).format(
    'YYYY-MM-DDTHH:mm:ss'
  )
  const {
    caseNum,
    title,
    subtitle,
    quantity,
    location,
    victimizer,
    killingType,
    happenedOn,
    description,
    shortDescription,
    evidence,
    remark,
    createdAt,
  } = errors

  return (
    <Layout title='Edit'>
      <form onSubmit={onSubmit} noValidate>
        <div className='row'>
          <TextInputField
            label='Title'
            id='title'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />

          <TextInputField
            label='Subtitle'
            id='subtitle'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='Subtitle'
            error={subtitle}
          />
          <TextInputField
            label='location'
            id='location'
            name='location'
            value={formData.location}
            onChange={onChange}
            placeholder='Region, zone, woreda, kebele'
            error={location}
          />
        </div>

        <div className='row'>
          <TextInputField
            label='Number of people killed'
            id='quantity'
            name='quantity'
            value={formData.quantity}
            onChange={onChange}
            placeholder='የተገደለ ሰው ብዛት'
            error={quantity}
          />
          <TextInputField
            label='Victimizer'
            id='victimizer'
            name='victimizer'
            value={formData.victimizer}
            onChange={onChange}
            placeholder='ገዳይ/ገዳዮች'
            error={victimizer}
          />
          <TextInputField
            label='Killing Type'
            id='killingType'
            type='text'
            name='killingType'
            value={formData.killingType}
            onChange={onChange}
            placeholder='የአገዳደል ሁኔታ'
            error={killingType}
          />
        </div>
        <div className='row'>
          <TextInputField
            label='Killing Date and time'
            type='datetime-local'
            id='happenedOn'
            name='happenedOn'
            value={happenedOnFormatted}
            onChange={onChange}
            error={happenedOn}
          />
          <TextInputField
            label='Publish'
            type='datetime-local'
            id='createdAt'
            name='createdAt'
            value={createdAtFormatted}
            onChange={onChange}
            error={createdAt}
          />
          <div></div>
        </div>

         <div className='row'>
      <TextInputField
          label='Case Number'
          id='caseNum'
          name='caseNum'
          value={formData.caseNum}
          onChange={onChange}
          placeholder='Case Number'
          error={caseNum}
        />
      </div>
         <TextAreaField
        label='Short Description'
        id='shortDescription'
        name='shortDescription'
        cols='120'
        rows='1'
        value={formData.shortDescription || ''}
        onChange={onChange}
        placeholder='Short description for table...'
        error={shortDescription}
      />
      <TextAreaField
        label='Evidence'
        id='evidence'
        name='evidence'
        cols='120'
        rows='1'
        value={formData.evidence}
        onChange={onChange}
        placeholder='Evidence links separated by comma and one space'
        error={evidence}
      />
      <TextAreaField
        label='Remarks'
        id='remark'
        name='remark'
        cols='120'
        rows='1'
        value={formData.remark}
        onChange={onChange}
        placeholder='remark ...'
        error={remark}
      />


        <TextAreaField
          label='Details'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Your post  goes here ...'
          error={description}
        />
        
        <div className='form-group file-upload'>
          <input
            type='file'
            name='files'
            id='files'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
            multiple
            // className={styles.inputfile}
          />
          {/* <label htmlFor='image'>
            {' '}
            <i className='fas fa-upload'></i>
            Upload Image
          </label> */}
        </div>

        <button className='btn' type='submit' id='submit-button'>
          Update
        </button>
      </form>
    </Layout>
  )
}

EditHistory.propTypes = {}

export default EditHistory
