import React, { useState } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

// background-color: #2acfcf;

const Ul = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  list-style: none;
  z-index: 20;
  li {
    padding: 15px 10px;
    font-weight: 600;
  }
  .active {
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 10px solid #00e091;
    border-bottom: 3px solid red;
    border-radius: 2px;
    border-radius-bottom: 5px;
    position: relative;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
    background: #f1ebf1;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    margin: 0;
    li {
      color: #fff;
    }
    a {
      font-size: 0.75rem !important;
    }
  }
`

const RightNav = ({ open, auth, handleSignOut }) => {
  const { isAuthenticated, user } = auth
  return (
    <Ul open={open}>
    {
      /* 
      <li>
        <NavLink exact activeClassName='active' to='/'>
          Home
        </NavLink>
      </li>
      
      */
    }
      
      <li>
        <NavLink activeClassName='active' to='/about'>
          About
        </NavLink>
      </li>
       <li>
        <NavLink activeClassName='active' to='/events'>
          Events
        </NavLink>
      </li>
     
      {
        /*
         <li>
        <NavLink activeClassName='active' to='/victims'>
          Victims
        </NavLink>
      </li>
         <li>
        <NavLink exact activeClassName='active' to='/massacres'>
          Massacre
        </NavLink>
      </li>
      <li>
        <NavLink exact activeClassName='active' to='/displacements'>
          Displacements
        </NavLink>
      </li>

      <li>
        <NavLink activeClassName='active' to='/images'>
          Images
        </NavLink>
      </li>

      <li>
        <NavLink exact activeClassName='active' to='/videos'>
          Video
        </NavLink>
      </li>
      
        */
      }
     
     {
       /*
        <li>
        <NavLink exact activeClassName='active' to='/reports'>
          Report
        </NavLink>
      </li>
       */
     }

      {isAuthenticated && (
        <li>
          <NavLink exact activeClassName='active' to='/dashboard'>
            Dashboard
          </NavLink>
        </li>
      )}

      {isAuthenticated ? (
        <li>
          <button
            className='nav-link btn btn-logout'
            to='/logout'
            onClick={handleSignOut}
          >
            Logout
          </button>
        </li>
      ) : (
        <li>
          <NavLink exact activeClassName='active' to='/signin'>
            Sign In
          </NavLink>
        </li>
      )}
    </Ul>
  )
}

const Nav = styled.nav`
  width: 100%;
  height: 150px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    font-size: 26px;
  }
  .brand-name {
    letter-space: 150%;
    text-transform:uppercase;
    font-weight:600,
  }
`

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index:50;
  display:none;
  @media(max-width:768px){
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  justify-items: center;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? 'red' : 'red')};
    border-radius: 10px;
     transform-origin:1px;
     transition: all 0.3s linear;
     &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
     &:nth-child(2) {
      transform: ${({ open }) => (open ? 'rotate(100%)' : 'rotate(0)')};
      opacity:${({ open }) => (open ? 0 : 1)}
    }
     &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
`

const Burger = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav
        open={open}
        auth={props.auth}
        handleSignOut={props.handleSignOut}
      />
    </>
  )
}
const Navbar = (props) => {
  const handleSignOut = (e) => {
    e.preventDefault()
    axios
      .get('/api/users/signout')
      .then((res) => {})
      .catch((err) => console.log(err))
    props.signOut()
    window.location.href = '/signin'
  }
  return (
    <Nav>
      <div className='logo' >
        <NavLink to='/' className='brand-name'>
         Amhara Genocide
        </NavLink>
      </div>

      <Burger auth={props.auth} handleSignOut={handleSignOut} />
    </Nav>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps, { signOut })(Navbar)
