import React from 'react'
import PropTypes from 'prop-types'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

export const SocialButton = ({
  Component,
  Icon,
  url,
  quote,
  hashtag,
  className,
  size,
}) => {
  return (
    <>
      <Component
        url={url}
        quote={quote}
        hashtag={hashtag}
        className={className}
      >
        <Icon size={size} round={true} />
      </Component>
    </>
  )
}

const SocialShareButton = ({category, id}) => (
  <>
    <SocialButton
      Component={FacebookShareButton}
      url={`https://amharagenocide.net/${category}/${id}`}
      quote={'Ongoing genocide on Amhara people'}
      hashtag='#StopAmharaGenocide'
      Icon={FacebookIcon}
    />{' '}
    <SocialButton
      Component={TwitterShareButton}
      url={`https://amharagenocide.net/${category}/${id}`}
      quote={'Ongoing genocide on Amhara people'}
      hashtag='#StopAmharaGenocide'
      Icon={TwitterIcon}
    />{' '}
    <SocialButton
      Component={TelegramShareButton}
      url={`https://amharagenocide.net/${category}/${id}`}
      quote={'Ongoing genocide on Amhara people'}
      hashtag='#StopAmharaGenocide'
      Icon={TelegramIcon}
    />{' '}
    <SocialButton
      Component={LinkedinShareButton}
      url={`https://amharagenocide.net/${category}/${id}`}
      quote={'Ongoing genocide on Amhara people'}
      hashtag='#StopAmharaGenocide'
      Icon={LinkedinIcon}
    />
  </>
)

export default SocialShareButton
